import React, { useState, useEffect, useCallback } from "react";
import adminLayout from "../hoc/adminLayout";
import Chart from "react-apexcharts";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import nurse_icon from "../images/icon/nurse_icon.svg";
import code_blue from "../images/icon/code_blue.svg";
import escalation_icon from "../images/icon/escalation_icon.svg";
import toilet_icon from "../images/icon/toilet_icon.svg";
import help_icon from "../images/icon/help_icon.svg";
import house_icon from "../images/icon/house_icon.svg";
import emptyrecord from "../assets/images/empty.png";
import logoimage from "../assets/images/siscall.png";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import debounce from "lodash/debounce";
import baseURL from "../common/base-urls.js";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { useNavigate, useLocation } from "react-router-dom";

const NurseStation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [apiUrl, setApiUrl] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(500); 
    //#region ====================================================== Create State =====================================================
    const [state, setState] = useState({
        pagination: [],
        show_toast: false,
        toast_msg: "No record found",
        show_modal: false,
        filter_applied: false,
        show_filter: false,
        modal_title: "Confirmation",
        modal_msg:
            "Are you absolutely certain you want to delete this record? This action cannot be reversed.",
        modal_button: "Delete",
        fields: {
            hospital_id: "",
        },
        apifields: {
            hospital_id: "",
        },
    });
    //#endregion
     // useEffect(() => {
    //     const currentTime = new Date();
    //     const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    //     //console.log(`=======================================`);
    //     //console.log(`========= Updated on ${formattedTime} =========`);
    //     //console.log(`=======================================`);
    //     //console.log(location);
    //     return () => {
    //     };
    // }, [location]);
    //#region ====================================================== Input Textbox ======================================================
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [name]: value,
            },
        }));
    };
    //#endregion
    //#region ====================================================== Update State ======================================================
    const updateState = (key, value) => {
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };
    //#endregion
    //#region ====================================================== Page Rights ======================================================
    const rightsURL = `user/chkuserrights?form_id=${24}`;
    const {
        data: tblhasrights,
        error: error1,
        isValidating: isValidating2,
    } = useSWR(rightsURL, () => getAPI(rightsURL, true), {
        revalidateOnReconnect: false,
        revalidateOnFocus: false,
    });
    //#endregion
    //#region ====================================================== Page Table Bind Data ======================================================
    let tbldata = {};
    let tblpagination = {};
    let tblhospitals = {};
    let apiHospital = `hospital/dropdown?page=1&limit=500`;
    useEffect(() => {
        if (
            tblhasrights &&
            tblhasrights.data &&
            Array.isArray(tblhasrights.data.hasrights) &&
            tblhasrights.data.hasrights.length > 0 &&
            tblhasrights.data.hasrights[0].hasOwnProperty('view_status') &&
            tblhasrights.data.hasrights[0]['view_status'] && state.apifields.hospital_id != ""
        ) {

            // const newApiUrl = `report/home?${queryString}`; 
            const queryParams = {
                page: page,
                limit: limit,
                hospital_id: state.apifields.hospital_id
            };
            // Convert the filtered parameters into a query string
            // Filter out null or blank parameters
            const filteredParams = Object.fromEntries(
                Object.entries(queryParams).filter(([_, value]) => value != null && value !== '')
            );
            const queryString = new URLSearchParams(filteredParams).toString();
            // Construct the final apiUrl
            const newApiUrl = `report/displaywithip?${queryString}`;
            setApiUrl(newApiUrl);

            if (!state.show_filter || !state.filter_applied) {
                return;
            }
            if (queryString && queryString != `page=1&limit=${limit}`) {
            } else {
                showToast("Please enter/select at least one filter criteria to apply the filter.");
            }
        }
    }, [tblhasrights, state.apifields, state.show_filter, page, limit]);
    const { data, error, isValidating } = useSWR(
        apiUrl,
        () => getAPI(apiUrl, false),
        {
            revalidateOnReconnect: false,
            revalidateOnFocus: false,
        }
    );
    tbldata = data;
    //#region ====================================================== Pagination & Page Changed ======================================================
    useEffect(() => {
        try {
            if (tbldata && tbldata.data && tbldata.data.hasOwnProperty("count") && tbldata.data.count > 0) {
                let dblPageCount = Math.ceil(parseInt(tbldata.data.count) / limit);
                let pageCount = Math.max(1, dblPageCount);
                updateState("total_pages", pageCount);
                let rows = [];
                if (pageCount > 0) {
                    for (let i = 1; i <= pageCount; i++) {
                        if (i == 1 || i == pageCount) {
                            rows.push({ text: i.toString(), value: i.toString(), enabled: i != parseInt(page) });
                        }
                        else if ((i >= parseInt(page) - 2) && (i <= parseInt(page) + 3)) {
                            rows.push({ text: i.toString(), value: i.toString(), enabled: i != parseInt(page) });
                        }
                        else if (((i > parseInt(page) + 3) && (i >= pageCount - 2))) {
                            rows.push({ text: i.toString(), value: i.toString(), enabled: i != parseInt(page) });
                        }
                    }
                } else {
                    pageCount = '0';
                }
                setState((prevState) => ({
                    ...prevState,
                    pagination: rows
                }));
                tblpagination = rows;
                let firstpage = 1;
                let lastpage = 1;
                if (parseInt(page) === 1) {
                    if (limit * parseInt(page) <= parseInt(tbldata.data.count)) {
                        firstpage = 1;
                        lastpage = limit;
                    } else {
                        firstpage = 1;
                        lastpage = parseInt(tbldata.data.count);
                    }
                } else {
                    if (limit * parseInt(page) <= parseInt(tbldata.data.count)) {
                        firstpage = limit * (parseInt(page) - 1) + 1;
                        lastpage = limit * parseInt(page);
                    } else {
                        lastpage = parseInt(tbldata.data.count);
                        firstpage = limit * (parseInt(page) - 1) + 1;
                    }
                }
                updateState("lblpagedisplay", tbldata.data.count !== '0' ? `${firstpage} - ${lastpage}` : '0 - 0');
                if (lastpage === 0) {
                    firstpage = 0;
                    updateState("lblpagedisplay", tbldata.data.count !== '0' ? `${firstpage} - ${lastpage}` : '0 - 0');
                }
            }
        } catch (ex) {
        }
    }, [data]);
    const handlePageChange = (newPage) => {
        setPage(newPage);
    };
    //#endregion


    //#region ====================================================== Filter Section ======================================================
    const applyFilter = async () => {
        try {
            setState((prevState) => ({
                ...prevState,
                filter_applied: true,
                apifields: {
                    ...prevState.apifields,
                    hospital_id: prevState.fields.hospital_id,
                    display_id: prevState.fields.display_id,
                    ipaddress: prevState.fields.ipaddress
                },
            }));
            // //console.log(setState);
            setPage(1);
        } catch (err) { }
    };
    //#endregion



    //#region ====================================================== hospital Dropdown Bind ======================================================
    const { data: hospitaldata, errorhospital, isValidatinghospital } = useSWR(
        apiHospital,
        () => getAPI(apiHospital, false),
        {
            revalidateOnReconnect: false,
            revalidateOnFocus: false,
        }
    );
    tblhospitals = hospitaldata;
    //#endregion

    //#region ====================================================== Toast Message set ======================================================
    const showToast = (message) => {
        updateState("show_toast", true);
        updateState("toast_msg", message);
        setTimeout(() => {
            updateState("show_toast", false);
        }, 5000);
    };
    const hideToast = () => {
        updateState("show_toast", false);
    };
    //#endregion

    //#region ====================================================== Get API ======================================================
    const getAPI = async (url, Auth) => {
        try {
            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                if (Auth) {
                    url += `&user_id=${getUser.user_id}`;
                }
                const response = await axios.get(`${baseURL.serverUrl}${url}`, {
                    headers: {
                        "x-access-token": getUser.token,
                        Authorization: `Bearer ${getUser.token}`,
                    },
                });
                //console.log(response.data)
                return response.data;
            } else {
                return {};
            }
        } catch (err) {
            return {};
        }
    };
    //#endregion  

    const clearData = async () => {

    };
    //#region ====================================================== Post API ======================================================
    const postAPI = async (url, data, Auth) => {
        try {
            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                if (Auth) {
                    // Pass user_id through data
                    data.user_id = getUser.user_id;
                }
                const response = await axios.post(`${baseURL.serverUrl}${url}`, data, {
                    headers: {
                        "x-access-token": getUser.token,
                        Authorization: `Bearer ${getUser.token}`,
                        'Content-Type': 'application/json',
                    },
                });
                return response.data;
            } else {
                return {};
            }
        } catch (err) {
            return {};
        }
    };
    //#endregion 

    //#region ====================================================== Save Record ======================================================

    const debouncedSaveClicked = async (displayId) => {
        try {
            let newIpAddress = state.apifields[displayId]?.ip_address; 
            if (!newIpAddress || !state.apifields.hospital_id) {
                showToast(!state.apifields.hospital_id ? "Please select a hospital" : "IP address cannot be empty");
                return;
            }
            //console.log("insidebounce");
            const newapiFields = {
                "ID": displayId,
                "ip_address": newIpAddress,
                "hospital_id": state.apifields.hospital_id
            };

            const result = await postAPI('report/displayip', newapiFields, true);
            if (result && result.success === true) {
                showToast("Record has been updated successfully.");
            } else {
                showToast("Something went wrong. Please try again later.");
            }
        } catch (err) {
            console.error("An error occurred while updating IP address:", err);
            showToast("Something went wrong. Please try again later.");
        }
    };
    const handleInputChangenew = (e, displayId) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            apifields: {
                ...prevState.apifields,
                [displayId]: {
                    ip_address: value
                }
            }
        }));
    };
    useEffect(() => {
        if (
            tblhospitals &&
            tblhospitals.data &&
            tblhospitals.data.hasOwnProperty("hospitals") &&
            tblhospitals.data.hospitals.length == 1
        ) {
            setState((prevState) => ({
                ...prevState,
                fields: {
                    ...prevState.fields,
                    hospital_id: tblhospitals.data.hospitals[0].hospital_id,
                },
            }));
        }
    }, [tblhospitals]);
    return (
        <>
            {tblhasrights &&
                tblhasrights.data &&
                Array.isArray(tblhasrights.data.hasrights) &&
                tblhasrights.data.hasrights.length > 0 &&
                tblhasrights.data.hasrights[0].hasOwnProperty("view_status") &&
                tblhasrights.data.hasrights[0]["view_status"] && (
                    <>
                        <div className="row g-gs">
                            <div className="col-xxl-12">
                                <div className="card h-100">
                                    <div className="card-body boxpadding">
                                        <div className="card-title-group mb-4 align-items-start">
                                            <div className="card-title">
                                                <h4 className="title mb-0 lineheight0">Nurse Station List</h4>
                                                <span className="small subheader">View and manage the Nurse Stations in the hospital. Easily update the IP address of individual Nurse Stations for seamless communication.</span>
                                            </div>
                                        </div>
                                        <div className="row g-gs">
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <Select2
                                                        data={[
                                                            ...(tblhospitals && tblhospitals.data && tblhospitals.data.hasOwnProperty("hospitals") && tblhospitals.data.hospitals || []).map((x) => ({
                                                                text: x.hospital_name,
                                                                id: x.hospital_id,
                                                                key: x.hospital_id.toString(),
                                                            })),
                                                        ]}
                                                        options={{
                                                            placeholder: "Select hospital",
                                                        }}
                                                        name="hospital_id"
                                                        className="form-control"
                                                        value={state.fields.hospital_id}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary d-none d-md-inline-flex mr10"
                                                        name="btnfilter"
                                                        onClick={(e) => applyFilter()}
                                                    >
                                                        <em className="icon ni ni-search"></em>
                                                        <span>Preview</span>
                                                    </button>
                                                </div>
                                            </div>
                                            {/* ====================================================== Table data ====================================================== */}
                                            {tblhasrights &&
                                                tblhasrights.data &&
                                                Array.isArray(tblhasrights.data.hasrights) &&
                                                tblhasrights.data.hasrights.length > 0 &&
                                                tblhasrights.data.hasrights[0].hasOwnProperty("view_status") &&
                                                tblhasrights.data.hasrights[0]["view_status"] && (
                                                    <>
                                                        {tbldata &&
                                                            (!tbldata.data || !tbldata.data.displaylist?.length) ? (
                                                            <>
                                                                <div className="norecordcss">
                                                                    <img
                                                                        src={emptyrecord}
                                                                        width="330px"
                                                                        alt="No record found"
                                                                    />
                                                                    <div className="norecordfont">No record found</div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        {tbldata &&
                                                                            tbldata.data.displaylist.map((x) => (<>
                                                                                <div key={x?.h_display_id} className="row">
                                                                                    <div className="col-md-4">
                                                                                        <div className="form-group">
                                                                                            <label className="form-label">{x?.display_name} IP Address</label>
                                                                                            <div className="form-control-wrap">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    placeholder="Enter IP address"
                                                                                                    name={x?.h_display_id}
                                                                                                    className="form-control"
                                                                                                    value={x?.ip_address}
                                                                                                    onChange={(e) => handleInputChangenew(e, x.h_display_id)} // Assuming handleInputChange updates state.apifields for the specific display_id
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <div className="form-group">
                                                                                            <label className="form-label">&nbsp;</label>
                                                                                            <div className="form-control-wrap">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-primary"
                                                                                                    name="btnsave"
                                                                                                    id="btnsave"
                                                                                                    onClick={() => debouncedSaveClicked(x.h_display_id)}
                                                                                                >  <span> Update IP </span>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            ))}


                                                                    </div>
                                                                </div>


                                                            </>
                                                        )}
                                                    </>
                                                )}

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </>
                )}
                {/* ====================================================== Toast message ====================================================== */}
                {state.show_toast && (
                    <div
                        className="toast align-items-center text-bg-primary border-0 fade show toastmsg"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                    >
                        <div className="d-flex">
                            <div className="toast-body">{state.toast_msg}</div>
                            <button
                                type="button"
                                className="btn-close btn-close-white me-2 m-auto"
                                data-bs-dismiss="toast"
                                onClick={(e) => hideToast()}
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                )}
        </>
    );

};


export default adminLayout(NurseStation);