import React, { useState, useEffect, useCallback, useRef } from "react";
import adminLayout from "../hoc/adminLayout";
import Chart from "react-apexcharts";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import nurse_icon from "../images/icon/nurse_icon.svg";
import code_blue from "../images/icon/code_blue.svg";
import escalation_icon from "../images/icon/escalation_icon.svg";
import toilet_icon from "../images/icon/toilet_icon.svg";
import help_icon from "../images/icon/help_icon.svg";
import house_icon from "../images/icon/house_icon.svg";
import bedimg from "../assets/images/bed.png";
import ledimg from "../assets/images/led.png";
import emptyrecord from "../assets/images/empty.png";
import logoimage from "../assets/images/siscall.png";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import debounce from "lodash/debounce";
import baseURL from "../common/base-urls.js";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { useNavigate, useLocation } from "react-router-dom";

const Birdview = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [apiUrl, setApiUrl] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(3);
    const [tbldisplay, setTbldisplay] = useState([]);
    const [tblward, setTblward] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const tabRefs = useRef([]);
    const [showHospital, setShowHospital] = useState(true);

    const handleTabClick = (index) => {
        setActiveTab(index);
        // Scroll to the selected tab's div
        if (tabRefs.current[index]) {
            tabRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    //#region ====================================================== Create State =====================================================
    const [state, setState] = useState({
        pagination: [],
        show_toast: false,
        toast_msg: "No record found",
        show_modal: false,
        filter_applied: false,
        show_filter: false,
        modal_title: "Confirmation",
        modal_msg:
            "Are you absolutely certain you want to delete this record? This action cannot be reversed.",
        modal_button: "Delete",
        fields: {
            hospital_id: "",
            display_id: "",
            ward_id: ""
        },
        apifields: {
            hospital_id: "",
            display_id: "",
            ward_id: ""
        },
    });
    //#endregion
    useEffect(() => {
        const currentTime = new Date();
        const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        // //console.log(`=======================================`);
        // //console.log(`========= Updated on ${formattedTime} =========`);
        // //console.log(`=======================================`);
        // //console.log(location);

        let gethospitalid = localStorage.getItem("hospital_id");
        let hospitalid = null; // Example value
        if (gethospitalid) {
            hospitalid = gethospitalid;

        }
        // //console.log("hospitalid:", hospitalid, typeof hospitalid);

        // Check if hospitalid is the string "null"
        if (hospitalid !== "null" && hospitalid !== "0") {

            setShowHospital(false);
            setState((prevState) => ({
                ...prevState,
                fields: {
                    ...prevState.fields,
                    hospital_id: hospitalid,
                },
                apifields: {
                    ...prevState.fields,
                    hospital_id: hospitalid,
                },
            }));


            applyFilter();
        }
        return () => {
        };
    }, [location]);
    //#region ====================================================== Input Textbox ======================================================
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [name]: value,
            },
        }));
    };
    //#endregion
    //#region ====================================================== Update State ======================================================
    const updateState = (key, value) => {
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };
    //#endregion
    //#region ====================================================== Page Rights ======================================================
    const rightsURL = `user/chkuserrights?form_id=${12}`;
    const {
        data: tblhasrights,
        error: error1,
        isValidating: isValidating2,
    } = useSWR(rightsURL, () => getAPI(rightsURL, true), {
        revalidateOnReconnect: false,
        revalidateOnFocus: false,
    });
    //#endregion
    //#region ====================================================== Page Table Bind Data ======================================================
    let tbldata = {};
    let tblpagination = {};
    let tblhospitals = {};
    let apiHospital = `hospital/dropdown?page=1&limit=500`;

    let apiWard = `report/wardlist?page=1&limit=100`;
    let apiDisplay = `report/display?page=1&limit=100`;
    useEffect(() => {
        if (
            tblhasrights &&
            tblhasrights.data &&
            Array.isArray(tblhasrights.data.hasrights) &&
            tblhasrights.data.hasrights.length > 0 &&
            tblhasrights.data.hasrights[0].hasOwnProperty('view_status') &&
            tblhasrights.data.hasrights[0]['view_status'] && state.apifields.hospital_id != ""
        ) {
            //console.log("inside API");
            //pass hospital id and start enddate
            // const newApiUrl = `report/home?${queryString}`; 
            const queryParams = {
                hospital_id: state.apifields.hospital_id,
                display_id: state.apifields.display_id,
                ward_id: state.apifields.ward_id

            };
            // Filter out null or blank parameters
            const filteredParams = Object.fromEntries(
                Object.entries(queryParams).filter(([_, value]) => value != null && value !== '')
            );
            // Convert the filtered parameters into a query string
            const queryString = new URLSearchParams(filteredParams).toString();
            // Construct the final apiUrl
            const newApiUrl = `report/webbirdview?page=1&limit=1000&${queryString}`;
            setApiUrl(newApiUrl);
        }
    }, [tblhasrights, state.apifields, state.show_filter, page, limit]);
    const { data, error, isValidating } = useSWR(
        apiUrl,
        () => getAPI(apiUrl, false),
        {
            revalidateOnReconnect: false,
            revalidateOnFocus: false,
        }
    );
    tbldata = data;

    //#region ====================================================== shift Dropdown Bind ======================================================
    useEffect(() => {
        if (tblward && tblward.length == 0) {
            const fetchData = async () => {
                const { data: warddata, errorward, isValidatingward } = await getAPI(apiWard, false);
                setTblward(warddata);
                //  tblshift = shiftdata;
            };
            fetchData();
        }

        if (tbldisplay && tbldisplay.length == 0) {
            const fetchData = async () => {
                const { data: displaydata, errordisplay, isValidatingdisplay } = await getAPI(apiDisplay, false);
                setTbldisplay(displaydata);
                //  tblshift = shiftdata;
            };
            fetchData();
        }
    }, [tblhasrights, state.apifields, state.show_filter, page, limit]);
    // const { data: shiftdata, errorshift, isValidatingshift } = useSWR(
    //     apiShift,
    //     () => getAPI(apiShift, false),
    //     {
    //         revalidateOnReconnect: false,
    //         revalidateOnFocus: false,
    //     }
    // );
    // tblshift = shiftdata;
    //#endregion
    const formatDuration = (durationInSeconds) => {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = Math.round(durationInSeconds % 60);

        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    };
    const pad = (number) => {
        return number.toString().padStart(2, '0');
    };
    //#region ====================================================== Filter Section ======================================================
    const applyFilter = async () => {
        try {
            setState((prevState) => ({
                ...prevState,
                filter_applied: true,
                apifields: {
                    ...prevState.apifields,
                    hospital_id: prevState.fields.hospital_id,
                    display_id: prevState.fields.display_id,
                    ward_id: prevState.fields.ward_id

                },
            }));
            // //console.log(setState);
            setPage(1);
        } catch (err) { }
    };
    const clearFilter = async () => {
        try {
            const updatedapifieldsState = {
                hospital_id: state.fields.hospital_id,
                display_id: "",
                ward_id: ""
            };
            const updatedfieldState = {
                hospital_id: state.fields.hospital_id,
                display_id: "",
                ward_id: ""

            };
            updateState("apifields", updatedapifieldsState);
            updateState("fields", updatedfieldState);
            updateState("filter_applied", false);
        } catch (err) { }
    };
    const showFilter = async () => {
        try {
            updateState("show_filter", true);
        } catch (err) { }
    };
    const hideFilter = async () => {
        try {
            updateState("show_filter", false);
        } catch (err) { }
    };
    //#endregion 
    //#endregion
    //#region ====================================================== Multi Select2 Dropdown ======================================================
    const handleSelectMultipleChangedisplay = (event) => {
        const selectedOptions = Array.from(
            event.target.selectedOptions,
            (option) => option.value
        );
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                display_id: selectedOptions,
            },
        }));
    };
    //#endregion
    //#region ====================================================== Multi Select2 Dropdown ======================================================
    const handleSelectMultipleChangeward = (event) => {
        const selectedOptions = Array.from(
            event.target.selectedOptions,
            (option) => option.value
        );
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                ward_id: selectedOptions,
            },
        }));
    };
    //#endregion


    //#region ====================================================== hospital Dropdown Bind ======================================================
    const { data: hospitaldata, errorhospital, isValidatinghospital } = useSWR(
        apiHospital,
        () => getAPI(apiHospital, false),
        {
            revalidateOnReconnect: false,
            revalidateOnFocus: false,
        }
    );
    tblhospitals = hospitaldata;
    //#endregion

    //#region ====================================================== Toast Message set ======================================================
    const showToast = (message) => {
        updateState("show_toast", true);
        updateState("toast_msg", message);
        setTimeout(() => {
            updateState("show_toast", false);
        }, 5000);
    };
    const hideToast = () => {
        updateState("show_toast", false);
    };
    //#endregion

    //#region ====================================================== Get API ======================================================
    const getAPI = async (url, Auth) => {
        try {
            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                if (Auth) {
                    url += `&user_id=${getUser.user_id}`;
                }
                const response = await axios.get(`${baseURL.serverUrl}${url}`, {
                    headers: {
                        "x-access-token": getUser.token,
                        Authorization: `Bearer ${getUser.token}`,
                    },
                });
                //console.log(response.data)
                return response.data;
            } else {
                return {};
            }
        } catch (err) {
            return {};
        }
    };
    //#endregion  
    function convertToAMPM(time) {
        var hours = parseInt(time);
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert to 12-hour format
        return hours + ' ' + ampm;
    }
    //#region ====================================================== Date validation ======================================================
    const handleDateChange = (event) => {
        const { name, value } = event.target;
        const { start_date, end_date } = state.fields;
        const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(value);
        if (!isValidDate) {
            showToast("Invalid date format");
            return;
        }
        if (name === "start_date" && new Date(value) > new Date(end_date)) {
            showToast("Start date cannot be greater than end date");
            return;
        }
        if (name === "end_date" && new Date(value) < new Date(start_date)) {
            showToast("End date cannot be less than start date");
            return;
        }
        // Update the state
        setState((prevState) => ({
            ...prevState,
            fields: { ...prevState.fields, [name]: value },
        }));
    };
    //#endregion
    useEffect(() => {
        if (
            tblhospitals &&
            tblhospitals.data &&
            tblhospitals.data.hasOwnProperty("hospitals") &&
            tblhospitals.data.hospitals.length == 1
        ) {
            setState((prevState) => ({
                ...prevState,
                fields: {
                    ...prevState.fields,
                    hospital_id: tblhospitals.data.hospitals[0].hospital_id,
                },
            }));
        }
    }, [tblhospitals]);
    //#region ====================================================== Checkbox Handler ======================================================
    const handleCheckboxClick = () => {
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                is_escalated: !prevState.fields.is_escalated,
            },
        }), () => {
            //console.log(state);
        });
    };
    //#endregion 
    return (
        <>
            {tblhasrights &&
                tblhasrights.data &&
                Array.isArray(tblhasrights.data.hasrights) &&
                tblhasrights.data.hasrights.length > 0 &&
                tblhasrights.data.hasrights[0].hasOwnProperty("view_status") &&
                tblhasrights.data.hasrights[0]["view_status"] && (
                    <>
                        <div className="row g-gs">
                            <div className="col-xxl-12">
                                <div className="card h-100">
                                    <div className="card-body boxpadding">
                                        <div className="card-title-group mb-4 align-items-start">
                                            <div className="card-title">
                                                <h4 className="title mb-0 lineheight0">Birdview</h4>
                                                <span className="small subheader">A comprehensive screen displaying real-time connectivity status of wards and beds within the nurse calling system, ensuring efficient monitoring and response.</span>
                                            </div>
                                            {/* <div className="card-tools">
                                                {!state.show_filter && (
                                                    <>
                                                        <button
                                                            type="button"
                                                            className="btn btn-color-dark d-none d-md-inline-flex  mr10"
                                                            name="btnshowfilter"
                                                            onClick={(e) => showFilter()}
                                                        >
                                                            <em className="icon ni ni-filter"></em>
                                                            <span>Show Filter</span>
                                                        </button>
                                                    </>
                                                )}
                                            </div> */}
                                        </div>
                                        <div className="row g-gs">
                                            {showHospital ? <>  <div className="col-lg-3">
                                                <div className="form-group">
                                                    <Select2
                                                        data={[
                                                            ...(tblhospitals && tblhospitals.data && tblhospitals.data.hasOwnProperty("hospitals") && tblhospitals.data.hospitals || []).map((x) => ({
                                                                text: x.hospital_name,
                                                                id: x.hospital_id,
                                                                key: x.hospital_id.toString(),
                                                            })),
                                                        ]}
                                                        options={{
                                                            placeholder: "Select hospital",
                                                        }}
                                                        name="hospital_id"
                                                        className="form-control"
                                                        value={state.fields.hospital_id}
                                                        onChange={handleInputChange}
                                                    />

                                                </div>
                                            </div>
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary d-none d-md-inline-flex mr10"
                                                            name="btnfilter"
                                                            onClick={(e) => applyFilter()}
                                                        >
                                                            <em className="icon ni ni-search"></em>
                                                            <span>Preview</span>
                                                        </button>
                                                    </div>
                                                </div> </> : <></>
                                            }
                                            <div className="col-lg-6 titlealign">
                                                {tbldata && tbldata.data && tbldata.data ? (
                                                    <>
                                                        <div className="small titlesubcolor">
                                                            {/* <div>
                                                                        {tbldata.data.devicetitle}
                                                                    </div> */}
                                                            <div>
                                                                {tbldata.data.devicedescription}
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className="norecordcss1">
                                                    </div>
                                                )}
                                            </div>
                                            {/* ====================================================== Filter section ====================================================== */}
                                            {state.show_filter && (
                                                <>
                                                    <div>
                                                        <span className="dividerlist  mt-0"></span>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <h5 className="summary">
                                                                    Filter
                                                                    <span className="float-end">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-color-danger d-none d-md-inline-flex"
                                                                            name="btnhidefilter"
                                                                            onClick={(e) => hideFilter()}
                                                                        >
                                                                            <em className="icon ni ni-cross"></em>
                                                                            <span>Hide filter</span>
                                                                        </button>
                                                                    </span>
                                                                </h5>
                                                                <p className="ptitle">
                                                                    Refine your search effortlessly with our advanced
                                                                    filtering options.
                                                                </p>
                                                            </div>
                                                            <div className="col-md-12 mt-0">
                                                                <div className="row">

                                                                    <div className="col-lg-3">
                                                                        <div className="form-group">
                                                                            <label className="form-label">
                                                                                Nurse station
                                                                            </label>
                                                                            <Select2
                                                                                multiple
                                                                                data={[
                                                                                    ...(tbldisplay && tbldisplay && tbldisplay.hasOwnProperty("displaylist") && tbldisplay.displaylist || []).map((x) => ({
                                                                                        text: x.display_name,
                                                                                        id: x.display_id,
                                                                                        key: x.display_id.toString(),
                                                                                    })),
                                                                                ]}
                                                                                options={{
                                                                                    placeholder: "select multiple option",
                                                                                }}
                                                                                name="displays"
                                                                                className="form-control"
                                                                                value={state.fields.display_id}
                                                                                onChange={handleSelectMultipleChangedisplay}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-3">
                                                                        <div className="form-group">
                                                                            <label className="form-label">
                                                                                Ward
                                                                            </label>
                                                                            <Select2
                                                                                multiple
                                                                                data={[
                                                                                    ...(tblward && tblward.hasOwnProperty("ward") && tblward.wardlist || []).map((x) => ({
                                                                                        text: x.ward_name,
                                                                                        id: x.ward_id,
                                                                                        key: x.ward_id.toString(),
                                                                                    })),
                                                                                ]}
                                                                                options={{
                                                                                    placeholder: "select multiple option",
                                                                                }}
                                                                                name="wards"
                                                                                className="form-control"
                                                                                value={state.fields.ward_id}
                                                                                onChange={handleSelectMultipleChangeward}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-primary d-none d-md-inline-flex mr10"
                                                                                name="btnfilter"
                                                                                onClick={(e) => applyFilter()}
                                                                            >
                                                                                <em className="icon ni ni-search"></em>
                                                                                <span>Filter</span>
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-color-info d-none d-md-inline-flex"
                                                                                name="btnclearfilter"
                                                                                onClick={(e) => clearFilter()}
                                                                            >
                                                                                <em className="icon ni ni-delete"></em>
                                                                                <span>Clear filter</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <span className="dividerlist mt-4 mb-4"></span>
                                                    </div>
                                                </>
                                            )}

                                        </div>

                                        <div className="nk-block">
                                            {/* <ul className="nav nav-tabs mb-3 nav-tabs-s1 pos-sticky">
                                                {tbldisplay && tbldisplay.displaylist && tbldisplay.displaylist.map((x, index) => (
                                                    <li key={x.display_id} className="nav-item">
                                                        <a
                                                            className={`nav-link ${index === activeTab ? 'active' : ''}`}
                                                            href={`#NS${x.display_id}`}
                                                            onClick={() => handleTabClick(index)}
                                                        >
                                                            {x.display_name}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul> */}

                                            {/* <ul className="nav nav-tabs mb-3 nav-tabs-s1 pos-sticky">
                                                {tbldisplay && tbldisplay.displaylist && tbldisplay.displaylist.map((x, index) => (
                                                    <li key={x.display_id} className="nav-item">
                                                        <a className={`nav-link ${index === 0 ? 'active' : ''}`} href={`#NS${x.display_id}`}>
                                                            {x.display_name}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul> */}


                                            <div className="tab-content" id="myTabContent">
                                                {tbldata && tbldata.data && tbldata.data.hasOwnProperty("bedsByWard") && tbldata.data.bedsByWard.length > 0 ? (
                                                    <>

                                                        {tbldata && tbldata.data && tbldata.data.hasOwnProperty("bedsByWard") && tbldata.data.bedsByWard && tbldata.data.bedsByWard.map((display, index) => (
                                                            <div key={display.display_id} className="tab-pane fade show active" id={`#NS${display.display_id}`} ref={ref => tabRefs.current[index] = ref}  >
                                                                {display.wards.map(ward => (
                                                                    <div key={ward.ward_id} className="row">
                                                                        <div className="col-md-12">
                                                                            <h5 className="summary">{display.display_name}</h5>
                                                                            <div className="ptitle">
                                                                                <span className="spanbed">{ward.ward_name}</span>
                                                                            </div>

                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="birdview">
                                                                                {ward.beds.map(bed => (
                                                                                    <a key={bed.h_bt_id} className={`birdview-div ${bed.is_disconnected ? 'birdview-failed' : 'birdview-success'}`}>
                                                                                        <img src={bed.bt_type_id == "1" ? bedimg : ledimg} className="birdview-img" />
                                                                                        <span className={`birdview-span ${bed.is_disconnected ? 'birdview-span-failed' : 'birdview-span-success'}`}>
                                                                                            {bed.bt_name}
                                                                                        </span>
                                                                                    </a>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}

                                                                {/* <a key={bed.h_bt_id} href={`/${bed.h_bt_id}`} className={`birdview-div ${bed.is_disconnected ? 'birdview-failed' : 'birdview-success'}`}>
                                                                    <img src={bed.bt_type_id == "1" ? bedimg : ledimg} className="birdview-img" />
                                                                    <span className={`birdview-span ${bed.is_disconnected ? 'birdview-span-failed' : 'birdview-span-success'}`}>
                                                                        {bed.bt_name}
                                                                    </span>
                                                                </a> */}

                                                            </div>
                                                        ))}

                                                    </>
                                                ) : (
                                                    <div className="norecordcss1">
                                                        <img src={emptyrecord} width="330px" alt="No record found" />
                                                        <div className="norecordfont">No record found</div>
                                                    </div>
                                                )}



                                            </div>
                                        </div>
                                        <div className="divbottom">
                                            <div className="bottombutton bottombtn">
                                                <ul className="d-flex align-items-center gap g-3">
                                                    <li>
                                                        <span className="bird-total ">

                                                            <span className="bird-green">
                                                            </span>
                                                            <span className="bird-text">
                                                                Connected
                                                            </span>
                                                        </span>
                                                    </li>

                                                    <li>
                                                        <span className="bird-total ">
                                                            <span className="bird-red ">
                                                            </span>
                                                            <span className="bird-text">
                                                                Disconnected
                                                            </span>

                                                        </span>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </>
                )}
            {/* ====================================================== Toast message ====================================================== */}
            {state.show_toast && (
                <div
                    className="toast align-items-center text-bg-primary border-0 fade show toastmsg"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                >
                    <div className="d-flex">
                        <div className="toast-body">{state.toast_msg}</div>
                        <button
                            type="button"
                            className="btn-close btn-close-white me-2 m-auto"
                            data-bs-dismiss="toast"
                            onClick={(e) => hideToast()}
                            aria-label="Close"
                        ></button>
                    </div>
                </div>
            )}
        </>
    );

};


export default adminLayout(Birdview);