import React, { useState, useEffect, useCallback, useRef } from "react";
import adminLayout from "../hoc/adminLayout";
import Chart from "react-apexcharts";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import nurse_icon from "../images/icon/nurse_icon.svg";
import code_blue from "../images/icon/code_blue.svg";
import escalation_icon from "../images/icon/escalation_icon.svg";
import toilet_icon from "../images/icon/toilet_icon.svg";
import help_icon from "../images/icon/help_icon.svg";
import house_icon from "../images/icon/house_icon.svg";
import bedimg from "../assets/images/bed.png";
import ledimg from "../assets/images/led.png";
import emptyrecord from "../assets/images/empty.png";
import logoimage from "../assets/images/siscall.png";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import debounce from "lodash/debounce";
import baseURL from "../common/base-urls.js";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { useNavigate, useLocation } from "react-router-dom";

const LiveDashboard = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [apiUrl, setApiUrl] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(3);
    const [tbldisplay, setTbldisplay] = useState([]);
    const [tblward, setTblward] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const tabRefs = useRef([]);
    const [timers, setTimers] = useState({});
    const [timersinterval, setTimersInterval] = useState({});
    const [currentTime, setCurrentTime] = useState('');
    const [showHospital, setShowHospital] = useState(true);

    // useEffect(() => {
    //     // Function to format the time in the desired format
    //     function formatTime(date) {
    //         const hours = date.getHours();
    //         const minutes = date.getMinutes();
    //         const seconds = date.getSeconds();
    //         const ampm = hours >= 12 ? 'PM' : 'AM';
    //         const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format
    //         return `${formattedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm}`;
    //     } 
    //     // Update the current time every second
    //     const intervalId = setInterval(() => {
    //         const currentDate = new Date();
    //         const formattedTime = formatTime(currentDate);
    //         setCurrentTime(formattedTime);
    //     }, 1000);

    //     // Clear the interval when the component unmounts
    //     return () => clearInterval(intervalId);
    // }, []);
    // useEffect(() => {
    //     // Clear all existing timers when component unmounts
    //     return () => {
    //         Object.values(timers).forEach(timerId => clearInterval(timerId));
    //     };
    // }, []); // Empty dependency array ensures the effect runs only once

    // function startTimer(duration, bedId) {
    //     const timerId = setInterval(() => {
    //         setTimers(prevTimers => ({
    //             ...prevTimers,
    //             [bedId]: prevTimers[bedId] ? prevTimers[bedId] + 1 : 1,
    //         }));
    //     }, 1000); // Increment timer every 1000ms (1 second)

    //     setTimers(prevTimers => ({
    //         ...prevTimers,
    //         [bedId]: timerId,
    //     }));
    // }

    // function stopTimer(bedId) {
    //     clearInterval(timers[bedId]);
    //     setTimers(prevTimers => {
    //         const updatedTimers = { ...prevTimers };
    //         delete updatedTimers[bedId];
    //         return updatedTimers;
    //     });
    // }

    function secondsToHHMMSSsum(totalSeconds) {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    const handleTabClick = (index) => {
        setActiveTab(index);
        // Scroll to the selected tab's div
        if (tabRefs.current[index]) {
            tabRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    //#region ====================================================== Create State =====================================================
    const [state, setState] = useState({
        pagination: [],
        show_toast: false,
        toast_msg: "No record found",
        show_modal: false,
        filter_applied: false,
        show_filter: false,
        modal_title: "Confirmation",
        hospital_changed: false,
        modal_msg:
            "Are you absolutely certain you want to delete this record? This action cannot be reversed.",
        modal_button: "Delete",
        fields: {
            hospital_id: "",
            display_id: "",
            ward_id: ""
        },
        apifields: {
            hospital_id: "",
            display_id: "",
            ward_id: ""
        },
    });
    //#endregion
    useEffect(() => {
        const currentTime = new Date();
        const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        // //console.log(`=======================================`);
        // //console.log(`========= Updated on ${formattedTime} =========`);
        // //console.log(`=======================================`);
        // //console.log(location);

        let gethospitalid = localStorage.getItem("hospital_id");
        let hospitalid = null; // Example value
        if (gethospitalid) {
            hospitalid = gethospitalid;
        }
        // //console.log("hospitalid:", hospitalid, typeof hospitalid);

        // Check if hospitalid is the string "null"
        if (hospitalid !== "null" && hospitalid !== "0") {

            setShowHospital(false);

            setState((prevState) => ({
                ...prevState,
                fields: {
                    ...prevState.fields,
                    hospital_id: hospitalid,
                },
                apifields: {
                    ...prevState.fields,
                    hospital_id: hospitalid,
                },
            }));
        }
        return () => {
        };
    }, [location]);
    //#region ====================================================== Input Textbox ======================================================
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [name]: value,
            },
        }));
    };
    //#endregion
    //#region ====================================================== Update State ======================================================
    const updateState = (key, value) => {
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };
    //#endregion
    //#region ====================================================== Page Rights ======================================================
    const rightsURL = `user/chkuserrights?form_id=${17}`;
    const {
        data: tblhasrights,
        error: error1,
        isValidating: isValidating2,
    } = useSWR(rightsURL, () => getAPI(rightsURL, true), {
        revalidateOnReconnect: false,
        revalidateOnFocus: false,
    });
    //#endregion
    //#region ====================================================== Page Table Bind Data ======================================================
    let tbldata = {};
    let tblpagination = {};
    let tblhospitals = {};
    let apiHospital = `hospital/dropdown?page=1&limit=500`;
    let apiWard = `report/wardlist?page=1&limit=100`;
    let apiDisplay = `report/display?page=1&limit=100`;
    useEffect(() => {
        console.log("inside API1");
        if (
            tblhasrights &&
            tblhasrights.data &&
            Array.isArray(tblhasrights.data.hasrights) &&
            tblhasrights.data.hasrights.length > 0 &&
            tblhasrights.data.hasrights[0].hasOwnProperty('view_status') &&
            tblhasrights.data.hasrights[0]['view_status'] && state.apifields.hospital_id != "" && state.apifields.display_id != ""
        ) {
            console.log("inside API");
            const queryParams = {
                hospital_id: state.apifields.hospital_id,
                display_id: state.apifields.display_id,
                ward_id: state.apifields.ward_id
            };
            const newApiUrl = `report/live/${state.apifields.hospital_id}/${state.apifields.display_id}`;
            setApiUrl(newApiUrl);
            mutate(newApiUrl);
        }
    }, [tblhasrights, state.apifields, state.show_filter, page, limit]);
    const { data, error, isValidating } = useSWR(
        apiUrl,
        () => getAPI(apiUrl, false),
        {
            revalidateOnReconnect: false,
            revalidateOnFocus: false,
        }
    );
    tbldata = data;
    // useEffect(() => {
    //     Object.values(timersinterval).forEach(timerId => {
    //         //console.log('Clearing timer:', timerId);
    //         clearInterval(timerId);
    //     });
    //     setTimers([]);
    //     setTimersInterval([]);
    //     setInterval(applyFilter, 10000);
    //     if (tbldata && tbldata.data && tbldata.data.live_feed && tbldata.data.live_feed.Log) {

    //         tbldata.data.live_feed.Log.forEach(bed => {
    //             startTimer(bed.duration, bed.bt_id.toString());
    //         });
    //     }
    //     function startTimer(duration, bedId) {
    //         let elapsedSeconds = duration;
    //         const timerId = setInterval(() => {
    //             elapsedSeconds += 1;
    //             setTimers(prevTimers => ({
    //                 ...prevTimers,
    //                 [bedId]: elapsedSeconds,
    //             }));

    //         }, 1000);

    //         setTimersInterval(prevTimers => ({
    //             ...prevTimers,
    //             [bedId]: timerId,
    //         }));

    //         //console.log('TimersInterval:', timersinterval);
    //     }
    //     return () => {
    //         Object.values(timers).forEach(timerId => clearInterval(timerId));
    //     };
    // }, [tbldata, state.apifields, state.show_filter]);
    useEffect(() => {
        Object.values(timersinterval).forEach(timerId => clearInterval(timerId));
        setTimers([]);
        setTimersInterval([]);

        // Start a new interval for applying the filter
        const filterIntervalId = setInterval(applyFilter, 10000);
        let ibedno = 1;
        // Start timers for each bed if tbldata is available
        // if (tbldata && tbldata.data && tbldata.data.live_feed && tbldata.data.live_feed.Log) {
        //     tbldata.data.live_feed.Log.forEach(bed => {
        //         startTimer(bed.duration, bed.bt_id.toString());
        //     });
        // }

        if (tbldata && tbldata.data && tbldata.data.live_feed && tbldata.data.live_feed.Log) {
            // console.log("tbldata.data.live_feed.Log");
            // console.log(tbldata.data.live_feed.Log);
            tbldata.data.live_feed.Log.forEach((bed, index) => {
                // const uniqueId = index + 1; // Use index + 1 as the unique ID
                startTimer(bed.duration, bed.bt_id.toString()); // Start timer with unique ID
            });
        }
        const clearTimersInterval = setInterval(() => {
            clearAllTimers();
        }, 10000);
        // if (tbldata && tbldata.data && tbldata.data.live_feed && tbldata.data.live_feed.Log) {
        //     tbldata.data.live_feed.Log.forEach(bed => {
        //         startTimer(bed.duration, ibedno.toString());
        //         ibedno = ibedno + 1;
        //     });
        // }
        // Cleanup function to clear intervals and timers when component unmounts
        return () => {
            Object.values(timersinterval).forEach(timerId => clearInterval(timerId));
            clearInterval(filterIntervalId);
            clearAllTimers();
            clearInterval(clearTimersInterval);
        };
    }, [tbldata, state.apifields, state.show_filter]);
    const clearAllTimers = () => {
        Object.values(timersinterval).forEach(timerId => clearInterval(timerId));
        setTimers({});
        setTimersInterval({});
    };

    function startTimer(duration, bedId) {
        let elapsedSeconds = duration;
        const timerId = setInterval(() => {
            elapsedSeconds += 1;
            setTimers(prevTimers => ({
                ...prevTimers,
                [bedId]: elapsedSeconds,
            }));
        }, 1000);

        setTimersInterval(prevTimers => ({
            ...prevTimers,
            [bedId]: timerId,
        }));
        console.log(timers);
    }

    //#region ====================================================== shift Dropdown Bind ======================================================
    useEffect(() => {
        //console.log("inside123");
        if (state.fields.hospital_changed == true && state.fields.hospital_id != "" && state.fields.hospital_id != "0") {
            //console.log("tbldisplay1");
            const fetchData = async () => {
                const { data: displaydata, errordisplay, isValidatingdisplay } = await getAPI(apiDisplay + "&hospital_id=" + state.fields.hospital_id, false);
                setTbldisplay(displaydata);
                setState(prevState => ({
                    ...prevState,
                    filter_applied: true,
                    fields: {
                        ...prevState.fields,

                        hospital_changed: false
                    },
                    apifields: {
                        ...prevState.apifields,
                        hospital_id: state.fields.hospital_id,
                        hospital_changed: false
                    }
                }));
                // state.fields.hospital_changed = false;
                const urlSearchParams = new URLSearchParams(window.location.search);
                const hospitalId = urlSearchParams.get('hospital_id');
                const displayId = urlSearchParams.get('display_id');


                if (hospitalId && displayId) {
                    if (hospitalId != "" && displayId != "") {
                        if (hospitalId == state.fields.hospital_id) {
                            setState(prevState => ({
                                ...prevState,
                                filter_applied: true,
                                fields: {
                                    ...prevState.apifields,
                                    hospital_id: hospitalId,
                                    display_id: displayId,
                                    ward_id: prevState.fields.ward_id // Assuming ward_id remains unchanged
                                },
                                apifields: {
                                    ...prevState.apifields,
                                    hospital_id: hospitalId,
                                    display_id: displayId,
                                    ward_id: prevState.fields.ward_id // Assuming ward_id remains unchanged
                                }
                            }));


                        }
                    }
                }
                //  tblshift = shiftdata;
            };
            fetchData();


        }
    }, [state.fields.hospital_changed]);
    // const { data: shiftdata, errorshift, isValidatingshift } = useSWR(
    //     apiShift,
    //     () => getAPI(apiShift, false),
    //     {
    //         revalidateOnReconnect: false,
    //         revalidateOnFocus: false,
    //     }
    // );
    // tblshift = shiftdata;
    //#endregion
    const formatDuration = (durationInSeconds) => {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = Math.round(durationInSeconds % 60);

        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    };
    const pad = (number) => {
        return number.toString().padStart(2, '0');
    };
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const hospitalId = urlSearchParams.get('hospital_id');
        const displayId = urlSearchParams.get('display_id');
        if (hospitalId && displayId) {
            if (hospitalId != "" && displayId != "") {
                setState(prevState => ({
                    ...prevState,
                    filter_applied: true,
                    fields: {
                        ...prevState.apifields,
                        hospital_id: hospitalId,
                        display_id: displayId,
                        ward_id: prevState.fields.ward_id // Assuming ward_id remains unchanged
                    },
                    apifields: {
                        ...prevState.apifields,
                        hospital_id: hospitalId,
                        display_id: displayId,
                        ward_id: prevState.fields.ward_id // Assuming ward_id remains unchanged
                    }
                }));
            }
        }
    }, []);

    //#region ====================================================== Filter Section ======================================================
    // const applyFilter = async () => {
    //     try {
    //         const { hospital_id, display_id } = state.fields;
    //         if (hospital_id != "" && hospital_id != "0" && display_id != "" && display_id != "0") {

    //             const queryParams = new URLSearchParams(window.location.search);
    //             queryParams.set('hospital_id', hospital_id);
    //             queryParams.set('display_id', display_id);
    //             const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    //             // Reload the page with the new URL
    //             window.location.href = newUrl;

    //         }
    //         else {
    //             showToast("Please select hospital & Nurse station first");
    //         }
    //     } catch (err) { }
    // };
    const applyFilter = async () => {
        try {
            const { hospital_id, display_id } = state.fields;
            if (hospital_id != "" && display_id != "") {
                setState(prevState => ({
                    ...prevState,
                    apifields: {
                        ...prevState.apifields,
                        hospital_id: hospital_id,
                        display_id: display_id,
                        hospital_changed: false
                    }
                }));
            }

            // if (hospital_id != "" && hospital_id != "0" && display_id != "" && display_id != "0") {

            //     const queryParams = new URLSearchParams(window.location.search);
            //     queryParams.set('hospital_id', hospital_id);
            //     queryParams.set('display_id', display_id);
            //     const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
            //     // Reload the page with the new URL
            //     window.location.href = newUrl;

            // }
            // else {
            //     showToast("Please select hospital & Nurse station first");
            // }
        } catch (err) { }
    };
    const clearFilter = async () => {
        try {
            const updatedapifieldsState = {
                hospital_id: state.fields.hospital_id,
                display_id: "",
                ward_id: ""
            };
            const updatedfieldState = {
                hospital_id: state.fields.hospital_id,
                display_id: "",
                ward_id: ""

            };
            updateState("apifields", updatedapifieldsState);
            updateState("fields", updatedfieldState);
            updateState("filter_applied", false);
        } catch (err) { }
    };
    const showFilter = async () => {
        try {
            updateState("show_filter", true);
        } catch (err) { }
    };
    const hideFilter = async () => {
        try {
            updateState("show_filter", false);
        } catch (err) { }
    };
    //#endregion 
    //#endregion
    //#region ====================================================== Multi Select2 Dropdown ======================================================
    const handleSelectMultipleChangedisplay = (event) => {
        const selectedOptions = Array.from(
            event.target.selectedOptions,
            (option) => option.value
        );
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                display_id: selectedOptions,
            },
        }));
    };
    //#endregion
    //#region ====================================================== Multi Select2 Dropdown ======================================================
    const handleSelectMultipleChangeward = (event) => {
        const selectedOptions = Array.from(
            event.target.selectedOptions,
            (option) => option.value
        );
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                ward_id: selectedOptions,
            },
        }));
    };
    //#endregion


    //#region ====================================================== hospital Dropdown Bind ======================================================
    const { data: hospitaldata, errorhospital, isValidatinghospital } = useSWR(
        apiHospital,
        () => getAPI(apiHospital, false),
        {
            revalidateOnReconnect: false,
            revalidateOnFocus: false,
        }
    );
    tblhospitals = hospitaldata;
    //#endregion
    useEffect(() => {

        if (state.fields.hospital_id != "" && state.fields.hospital_id != "0") {
            //console.log("insidehospit");
            setState(prevState => ({
                ...prevState,
                fields: {
                    ...prevState.fields,
                    hospital_changed: true
                },
                apifields: {
                    ...prevState.apifields,
                    hospital_changed: true
                }
            }));
        }

    }, [state.fields.hospital_id]);
    //#region ====================================================== Toast Message set ======================================================
    const showToast = (message) => {
        updateState("show_toast", true);
        updateState("toast_msg", message);
        setTimeout(() => {
            updateState("show_toast", false);
        }, 5000);
    };
    const hideToast = () => {
        updateState("show_toast", false);
    };
    //#endregion

    //#region ====================================================== Get API ======================================================
    const getAPI = async (url, Auth) => {
        try {
            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                if (Auth) {
                    url += `&user_id=${getUser.user_id}`;
                }
                const response = await axios.get(`${baseURL.serverUrl}${url}`, {
                    headers: {
                        "x-access-token": getUser.token,
                        Authorization: `Bearer ${getUser.token}`,
                    },
                });
                //console.log(response.data)
                return response.data;
            } else {
                return {};
            }
        } catch (err) {
            return {};
        }
    };
    //#endregion  
    function convertToAMPM(time) {
        var hours = parseInt(time);
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert to 12-hour format
        return hours + ' ' + ampm;
    }
    // useEffect(() => {
    //     if (tblhospitals && tbldisplay) {
    //         // Assuming you want to set fields.hospital_id and fields.display_id
    //         const defaultHospitalId = state.apifields.hospital_id; // Use hospital_id from apifields
    //         const defaultDisplayId = state.apifields.display_id; // Use display_id from apifields

    //         setState(prevState => ({
    //             ...prevState,
    //             fields: {
    //                 ...prevState.fields,
    //                 hospital_id: defaultHospitalId,
    //                 display_id: defaultDisplayId
    //             }
    //         }));
    //     }
    // }, [tblhospitals, tbldisplay]);

    //#region ====================================================== Date validation ======================================================
    const handleDateChange = (event) => {
        const { name, value } = event.target;
        const { start_date, end_date } = state.fields;
        const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(value);
        if (!isValidDate) {
            showToast("Invalid date format");
            return;
        }
        if (name === "start_date" && new Date(value) > new Date(end_date)) {
            showToast("Start date cannot be greater than end date");
            return;
        }
        if (name === "end_date" && new Date(value) < new Date(start_date)) {
            showToast("End date cannot be less than start date");
            return;
        }
        // Update the state
        setState((prevState) => ({
            ...prevState,
            fields: { ...prevState.fields, [name]: value },
        }));
    };
    //#endregion
    // function secondsToHHMMSSsum(secondstotal) {
    //     let seconds = secondstotal;
    //     const hours = Math.floor(seconds / 3600);
    //     const minutes = Math.floor((seconds % 3600) / 60);
    //     const remainingSeconds = seconds % 60;
    //     return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    // }
    //#region ====================================================== Checkbox Handler ======================================================
    const handleCheckboxClick = () => {
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                is_escalated: !prevState.fields.is_escalated,
            },
        }), () => {
            //console.log(state);
        });
    };
    //#endregion 
    useEffect(() => {
        if (
            tblhospitals &&
            tblhospitals.data &&
            tblhospitals.data.hasOwnProperty("hospitals") &&
            tblhospitals.data.hospitals.length == 1
        ) {
            setState((prevState) => ({
                ...prevState,
                fields: {
                    ...prevState.fields,
                    hospital_id: tblhospitals.data.hospitals[0].hospital_id,
                },
            }));
        }
    }, [tblhospitals]);

    return (
        <>
            {tblhasrights &&
                tblhasrights.data &&
                Array.isArray(tblhasrights.data.hasrights) &&
                tblhasrights.data.hasrights.length > 0 &&
                tblhasrights.data.hasrights[0].hasOwnProperty("view_status") &&
                tblhasrights.data.hasrights[0]["view_status"] && (
                    <>
                        <div className="row g-gs">
                            <div className="col-xxl-12">
                                <div className="card h-100">
                                    <div className="card-body boxpadding">
                                        <div className="card-title-group mb-4 align-items-start">
                                            <div className="card-title">
                                                <h4 className="title mb-0 lineheight0">Live Nurse Station</h4>
                                                <span className="small subheader">Providing real-time updates on current calls and their durations at each nurse station, ensuring timely responses to patient needs.</span>
                                            </div>
                                            {/* <div className="card-tools">
                                                {!state.show_filter && (
                                                    <>
                                                        <button
                                                            type="button"
                                                            className="btn btn-color-dark d-none d-md-inline-flex  mr10"
                                                            name="btnshowfilter"
                                                            onClick={(e) => showFilter()}
                                                        >
                                                            <em className="icon ni ni-filter"></em>
                                                            <span>Show Filter</span>
                                                        </button>
                                                    </>
                                                )}
                                            </div> */}
                                        </div>
                                        <div className="row g-gs">
                                            {showHospital ? (<div className="col-lg-3">
                                                <div className="form-group">
                                                    <Select2
                                                        data={[
                                                            ...(tblhospitals && tblhospitals.data && tblhospitals.data.hasOwnProperty("hospitals") && tblhospitals.data.hospitals || []).map((x) => ({
                                                                text: x.hospital_name,
                                                                id: x.hospital_id,
                                                                key: x.hospital_id.toString(),
                                                            })),
                                                        ]}
                                                        options={{
                                                            placeholder: "Select hospital",
                                                        }}
                                                        name="hospital_id"
                                                        className="form-control"
                                                        value={state.fields.hospital_id}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>) : <></>
                                            }
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <Select2
                                                        data={[
                                                            ...(tbldisplay && tbldisplay && tbldisplay.hasOwnProperty("displaylist") && tbldisplay.displaylist || []).map((x) => ({
                                                                text: x.display_name,
                                                                id: x.display_id,
                                                                key: x.display_id.toString(),
                                                            })),
                                                        ]}
                                                        options={{
                                                            placeholder: "Select nurse stations",
                                                        }}
                                                        name="display_id"
                                                        className="form-control"
                                                        value={state.fields.display_id}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary d-none d-md-inline-flex mr10"
                                                        name="btnfilter"
                                                        onClick={(e) => applyFilter()}
                                                    >
                                                        <em className="icon ni ni-search"></em>
                                                        <span>Preview</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 titlealign">

                                            </div>
                                            {/* ====================================================== Filter section ====================================================== */}
                                            {state.show_filter && (
                                                <>
                                                    <div>
                                                        <span className="dividerlist  mt-0"></span>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <h5 className="summary">
                                                                    Filter
                                                                    <span className="float-end">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-color-danger d-none d-md-inline-flex"
                                                                            name="btnhidefilter"
                                                                            onClick={(e) => hideFilter()}
                                                                        >
                                                                            <em className="icon ni ni-cross"></em>
                                                                            <span>Hide filter</span>
                                                                        </button>
                                                                    </span>
                                                                </h5>
                                                                <p className="ptitle">
                                                                    Refine your search effortlessly with our advanced
                                                                    filtering options.
                                                                </p>
                                                            </div>
                                                            <div className="col-md-12 mt-0">
                                                                <div className="row">


                                                                    <div className="col-lg-3">
                                                                        <div className="form-group">
                                                                            <label className="form-label">
                                                                                Ward
                                                                            </label>
                                                                            <Select2
                                                                                multiple
                                                                                data={[
                                                                                    ...(tblward && tblward.hasOwnProperty("ward") && tblward.wardlist || []).map((x) => ({
                                                                                        text: x.ward_name,
                                                                                        id: x.ward_id,
                                                                                        key: x.ward_id.toString(),
                                                                                    })),
                                                                                ]}
                                                                                options={{
                                                                                    placeholder: "select multiple option",
                                                                                }}
                                                                                name="wards"
                                                                                className="form-control"
                                                                                value={state.fields.ward_id}
                                                                                onChange={handleSelectMultipleChangeward}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-primary d-none d-md-inline-flex mr10"
                                                                                name="btnfilter"
                                                                                onClick={(e) => applyFilter()}
                                                                            >
                                                                                <em className="icon ni ni-search"></em>
                                                                                <span>Filter</span>
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-color-info d-none d-md-inline-flex"
                                                                                name="btnclearfilter"
                                                                                onClick={(e) => clearFilter()}
                                                                            >
                                                                                <em className="icon ni ni-delete"></em>
                                                                                <span>Clear filter</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <span className="dividerlist mt-4 mb-4"></span>
                                                    </div>
                                                </>
                                            )}

                                        </div>

                                        <div className="nk-block">

                                            <div className="tab-content" id="myTabContent">
                                                {tbldata && tbldata.data && tbldata.data.hasOwnProperty("live_feed") && tbldata.data.live_feed && Object.keys(tbldata.data.live_feed).length > 0 ? (
                                                    <>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <table className="datatable-init table" data-nk-container="table-responsive">
                                                                    <thead className="table-light borderno">
                                                                        <tr class-="borderno borderbottom">
                                                                            <th width="100px" className="tb-col liveth borderno"><span className="overline-title"> </span></th>
                                                                            <th width="100px" className="tb-col liveth borderno"><span className="overline-title"> </span></th>
                                                                            <th width="100px" className="tb-col liveth borderno"><span className="overline-title">Call</span></th>
                                                                            <th width="100px" className="tb-col liveth borderno"><span className="overline-title">Min</span></th>
                                                                            <th width="100px" className="tb-col liveth borderno"><span className="overline-title">Max</span></th>
                                                                            <th width="100px" className="tb-col liveth borderno"><span className="overline-title">Avg</span></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {tbldata && tbldata.data && tbldata.data.live_feed.hasOwnProperty("code_blue") && tbldata.data.live_feed.code_blue ? (
                                                                            <tr className="borderno borderbottom borderbottomback1">
                                                                                <td className="tb-col borderno">
                                                                                    <div className="media-group">
                                                                                        <div className="media-text"><a href="#" className="title">CODE BLUE</a> </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="tb-col borderno"> </td>
                                                                                <td className="tb-col borderno">{tbldata.data.live_feed.code_blue.call_count}</td>
                                                                                <td className="tb-col borderno">{tbldata.data.live_feed.code_blue.min}</td>
                                                                                <td className="tb-col borderno">{tbldata.data.live_feed.code_blue.max}</td>
                                                                                <td className="tb-col borderno">{tbldata.data.live_feed.code_blue.avg}</td>
                                                                            </tr>
                                                                        ) : (
                                                                            <span></span>
                                                                        )}
                                                                        {tbldata && tbldata.data && tbldata.data.live_feed.hasOwnProperty("extra") && tbldata.data.live_feed.extra ? (
                                                                            <tr className="borderno borderbottom borderbottomback2">
                                                                                <td className="tb-col borderno">
                                                                                    <div className="media-group">
                                                                                        <div className="media-text"><a href="#" className="title">EXTRA HELP</a> </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="tb-col borderno"> </td>
                                                                                <td className="tb-col borderno">{tbldata.data.live_feed.extra.call_count}</td>
                                                                                <td className="tb-col borderno">{tbldata.data.live_feed.extra.min}</td>
                                                                                <td className="tb-col borderno">{tbldata.data.live_feed.extra.max}</td>
                                                                                <td className="tb-col borderno">{tbldata.data.live_feed.extra.avg}</td>
                                                                            </tr>
                                                                        ) : (
                                                                            <span></span>
                                                                        )}
                                                                        {tbldata && tbldata.data && tbldata.data.live_feed.hasOwnProperty("nurse") && tbldata.data.live_feed.nurse ? (
                                                                            <tr className="borderno borderbottom borderbottomback3">
                                                                                <td className="tb-col borderno">
                                                                                    <div className="media-group">
                                                                                        <div className="media-text"><a href="#" className="title">NURSE CALL</a> </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="tb-col borderno"> </td>
                                                                                <td className="tb-col borderno">{tbldata.data.live_feed.nurse.call_count}</td>
                                                                                <td className="tb-col borderno">{tbldata.data.live_feed.nurse.min}</td>
                                                                                <td className="tb-col borderno">{tbldata.data.live_feed.nurse.max}</td>
                                                                                <td className="tb-col borderno">{tbldata.data.live_feed.nurse.avg}</td>
                                                                            </tr>
                                                                        ) : (
                                                                            <span></span>
                                                                        )}
                                                                        {tbldata && tbldata.data && tbldata.data.live_feed.hasOwnProperty("house") && tbldata.data.live_feed.house ? (
                                                                            <tr className="borderno borderbottom borderbottomback4">
                                                                                <td className="tb-col borderno">
                                                                                    <div className="media-group">
                                                                                        <div className="media-text"><a href="#" className="title">HOUSE CALL</a> </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="tb-col borderno"> </td>
                                                                                <td className="tb-col borderno">{tbldata.data.live_feed.house.call_count}</td>
                                                                                <td className="tb-col borderno">{tbldata.data.live_feed.house.min}</td>
                                                                                <td className="tb-col borderno">{tbldata.data.live_feed.house.max}</td>
                                                                                <td className="tb-col borderno">{tbldata.data.live_feed.house.avg}</td>
                                                                            </tr>
                                                                        ) : (
                                                                            <span></span>
                                                                        )}
                                                                        {tbldata && tbldata.data && tbldata.data.live_feed.hasOwnProperty("toilet") && tbldata.data.live_feed.toilet ? (
                                                                            <tr className="borderno borderbottom borderbottomback5">
                                                                                <td className="tb-col borderno">
                                                                                    <div className="media-group">
                                                                                        <div className="media-text"><a href="#" className="title">TOILET CALL</a> </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="tb-col borderno"> </td>
                                                                                <td className="tb-col borderno">{tbldata.data.live_feed.toilet.call_count}</td>
                                                                                <td className="tb-col borderno">{tbldata.data.live_feed.toilet.min}</td>
                                                                                <td className="tb-col borderno">{tbldata.data.live_feed.toilet.max}</td>
                                                                                <td className="tb-col borderno">{tbldata.data.live_feed.toilet.avg}</td>
                                                                            </tr>
                                                                        ) : (
                                                                            <span></span>
                                                                        )}
                                                                        {tbldata && tbldata.data && tbldata.data.live_feed.hasOwnProperty("total") && tbldata.data.live_feed.total ? (
                                                                            <tr className="borderno borderbottom borderbottomback6">
                                                                                <td className="tb-col borderno">
                                                                                    <div className="media-group">
                                                                                        <div className="media-text"><a href="#" className="title">TOTAL CALLS</a> </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="tb-col borderno"> </td>
                                                                                <td className="tb-col borderno">{tbldata.data.live_feed.total.call_count}</td>
                                                                                <td className="tb-col borderno">{tbldata.data.live_feed.total.min}</td>
                                                                                <td className="tb-col borderno">{tbldata.data.live_feed.total.max}</td>
                                                                                <td className="tb-col borderno">{tbldata.data.live_feed.total.avg}</td>
                                                                            </tr>
                                                                        ) : (
                                                                            <span></span>
                                                                        )}
                                                                        <tr className=" borderno borderbottom ">
                                                                            {tbldata && tbldata.data && tbldata.data.live_feed.hasOwnProperty("esc_count") && tbldata.data.live_feed.esc_count ? (
                                                                                <>      <td className="tb-col  borderno borderbottomback7">
                                                                                    <div className="media-group">
                                                                                        <div className="media-text"><a href="#" className="title">ESCALATED CALLS</a>  </div>
                                                                                    </div>
                                                                                </td>
                                                                                    <td className="tb-col  borderno borderbottomback7"> </td>
                                                                                    <td className="tb-col  borderno borderbottomback7">{tbldata.data.live_feed.esc_count}</td>
                                                                                </>) : (
                                                                                <>
                                                                                    <td className="tb-col  borderno borderbottomback7">
                                                                                        <div className="media-group">
                                                                                            <div className="media-text"><a href="#" className="title">ESCALATED CALLS</a>  </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="tb-col  borderno borderbottomback7"> </td>
                                                                                    <td className="tb-col  borderno borderbottomback7">0</td>
                                                                                </>
                                                                            )}
                                                                            {tbldata && tbldata.data && tbldata.data.live_feed.hasOwnProperty("fwd_count") && tbldata.data.live_feed.fwd_count ? (
                                                                                <>    <td className="tb-col  borderno borderbottomback8">
                                                                                    <div className="media-group">
                                                                                        <div className="media-text"><a href="#" className="title">FORWARDED CALLS</a>  </div>
                                                                                    </div>
                                                                                </td>
                                                                                    <td className="tb-col  borderno borderbottomback8"> </td>
                                                                                    <td className="tb-col  borderno borderbottomback8">{tbldata.data.live_feed.fwd_count}</td>

                                                                                </>) : (
                                                                                <>
                                                                                    <td className="tb-col  borderno borderbottomback8 btleft">
                                                                                        <div className="media-group">
                                                                                            <div className="media-text"><a href="#" className="title">FORWARDED CALLS</a>  </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="tb-col  borderno borderbottomback8"> </td>
                                                                                    <td className="tb-col  borderno borderbottomback8">0</td>
                                                                                </>
                                                                            )}


                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div>
                                                                    <h5 className="summary">On going calls</h5>
                                                                    {tbldata && tbldata.data.live_feed && tbldata.data.live_feed.hasOwnProperty("Log") && tbldata.data.live_feed.Log && tbldata.data.live_feed.Log.map((bed, index) => (
                                                                        <div className="oncall">
                                                                            <div className="oncall1">
                                                                                <img src={bed.bt_type_id == "1" ? bedimg : ledimg} className="live-img" />
                                                                            </div>
                                                                            <div className="oncall2 ">
                                                                                <div>
                                                                                    <div>
                                                                                        {bed.bt_name}
                                                                                    </div>
                                                                                    <div>
                                                                                        {bed.call_name}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="textright" >
                                                                                    <div>{secondsToHHMMSSsum(timers[bed.bt_id] || 0)}</div>

                                                                                    {bed.call_type_name == "None" ? "" : <div>
                                                                                        <span className="oncallstatus">
                                                                                            {bed.call_type_name}
                                                                                        </span>
                                                                                    </div>
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    ))}

                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div>
                                                                    <h5 className="summary">External calls</h5>
                                                                    {tbldata && tbldata.data.live_feed && tbldata.data.live_feed.hasOwnProperty("Ext_Log") && tbldata.data.live_feed.Ext_Log && tbldata.data.live_feed.Ext_Log.map((bed, index) => (
                                                                        <div className="oncall">
                                                                            <div className="oncall1">
                                                                                <img src={bed.bt_type_id == "1" ? bedimg : ledimg} className="live-img" />
                                                                            </div>
                                                                            <div className="oncall2 ">
                                                                                <div>
                                                                                    <div>
                                                                                        {bed.bt_name}
                                                                                    </div>
                                                                                    <div>
                                                                                        {bed.call_name}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="textright" >
                                                                                    <div>{secondsToHHMMSSsum(timers[bed.bt_id] || 0)}</div>
                                                                                    <div>
                                                                                        {bed.display_name}
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    ))}

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </>
                                                ) : (
                                                    <div className="norecordcss1">
                                                        <img src={emptyrecord} width="330px" alt="No record found" />
                                                        <div className="norecordfont">No record found</div>
                                                    </div>
                                                )}



                                            </div>
                                        </div>
                                        <div className="divbottom">
                                            <div className="bottombutton bottombuttonlive bottombtnjustify">
                                                <div className="font156">
                                                    {currentTime}
                                                </div>
                                                <div>
                                                    <div className="logo-wrap">
                                                        <img className="logo-svg" src={logoimage} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </>
                )}
            {/* ====================================================== Toast message ====================================================== */}
            {state.show_toast && (
                <div
                    className="toast align-items-center text-bg-primary border-0 fade show toastmsg"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                >
                    <div className="d-flex">
                        <div className="toast-body">{state.toast_msg}</div>
                        <button
                            type="button"
                            className="btn-close btn-close-white me-2 m-auto"
                            data-bs-dismiss="toast"
                            onClick={(e) => hideToast()}
                            aria-label="Close"
                        ></button>
                    </div>
                </div>
            )}
        </>
    );

};


export default adminLayout(LiveDashboard);