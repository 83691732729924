import React, { useState, useEffect, useCallback } from "react";
import adminLayout from "../hoc/adminLayout";
import Chart from "react-apexcharts";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import nurse_icon from "../images/icon/nurse_icon.svg";
import code_blue from "../images/icon/code_blue.svg";
import escalation_icon from "../images/icon/escalation_icon.svg";
import toilet_icon from "../images/icon/toilet_icon.svg";
import help_icon from "../images/icon/help_icon.svg";
import house_icon from "../images/icon/house_icon.svg";
import emptyrecord from "../assets/images/empty.png";
import logoimage from "../assets/images/siscall.png";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import debounce from "lodash/debounce";
import baseURL from "../common/base-urls.js";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { useNavigate, useLocation } from "react-router-dom";

const CallLog = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [apiUrl, setApiUrl] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);
    const [tblshift, setTblShift] = useState([]);
    const [tbldisplay, setTbldisplay] = useState([]);
    const [tblward, setTblward] = useState([]);
    const [tblcall, setTblcall] = useState([]);
    const [showHospital, setShowHospital] = useState(true);
    //#region ====================================================== Create State =====================================================
    const [state, setState] = useState({
        pagination: [],
        show_toast: false,
        toast_msg: "No record found",
        show_modal: false,
        filter_applied: false,
        show_filter: false,
        modal_title: "Confirmation",
        modal_msg:
            "Are you absolutely certain you want to delete this record? This action cannot be reversed.",
        modal_button: "Delete",
        call_api: false,
        fields: {
            hospital_id: "",
            start_date: "",
            end_date: "",
            shift_id: "",
            h_display_id: "",
            ward_id: "",
            call_id: "",
            is_escalated: ""
        },
        apifields: {
            hospital_id: "",
            start_date: "",
            end_date: "",
            shift_id: "",
            h_display_id: "",
            ward_id: "",
            call_id: "",
            is_escalated: ""
        },
    });
    //#endregion
    useEffect(() => {
        const currentTime = new Date();
        const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        // //console.log(`=======================================`);
        // //console.log(`========= Updated on ${formattedTime} =========`);
        // //console.log(`=======================================`);
        // //console.log(location);

        let gethospitalid = localStorage.getItem("hospital_id");
        let hospitalid = null; // Example value
        if (gethospitalid) {
            hospitalid = gethospitalid;
        }
        // //console.log("hospitalid:", hospitalid, typeof hospitalid);

        // Check if hospitalid is the string "null"
        if (hospitalid !== "null" && hospitalid !== "0") {

            setShowHospital(false);
            setState((prevState) => ({
                ...prevState,
                fields: {
                    ...prevState.fields,
                    hospital_id: hospitalid,
                },
                apifields: {
                    ...prevState.fields,
                    hospital_id: hospitalid,
                },
            }));
        }
        return () => {
        };
    }, [location]);
    //#region ====================================================== Input Textbox ======================================================
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [name]: value,
            },
        }));
    };
    //#endregion
    //#region ====================================================== Input Hospital ======================================================
    const handleInputChangeHospital = (event) => {
        const { name, value } = event.target;
        setState((prevState) => ({
            ...prevState,
            call_api: true,
            fields: {
                ...prevState.fields,
                [name]: value,
            },
            apifields: {
                ...prevState.fields,
                [name]: value,
            },
        }));
    };
    //#endregion
    //#region ====================================================== Update State ======================================================
    const updateState = (key, value) => {
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };
    //#endregion
    //#region ====================================================== Page Rights ======================================================
    const rightsURL = `user/chkuserrights?form_id=${20}`;
    const {
        data: tblhasrights,
        error: error1,
        isValidating: isValidating2,
    } = useSWR(rightsURL, () => getAPI(rightsURL, true), {
        revalidateOnReconnect: false,
        revalidateOnFocus: false,
    });
    //#endregion
    //#region ====================================================== Page Table Bind Data ======================================================
    let tbldata = {};
    let tblpagination = {};
    let tblhospitals = {};
    let apiHospital = `hospital/dropdown?page=1&limit=500`;
    let apiShift = `report/shift`;
    let apiWard = `report/wardlist?page=1&limit=100`;
    let apiDisplay = `report/display?page=1&limit=100`;
    let apiCall = `report/call?page=1&limit=50`;
    useEffect(() => {
        if (
            tblhasrights &&
            tblhasrights.data &&
            Array.isArray(tblhasrights.data.hasrights) &&
            tblhasrights.data.hasrights.length > 0 &&
            tblhasrights.data.hasrights[0].hasOwnProperty('view_status') &&
            tblhasrights.data.hasrights[0]['view_status'] && state.apifields.hospital_id != ""
        ) {
            //console.log("inside API");
            //pass hospital id and start enddate
            // const newApiUrl = `report/home?${queryString}`; 
            const queryParams = {
                page: page,
                limit: limit,
                hospital_id: state.apifields.hospital_id,
                start_date: state.apifields.start_date,
                end_date: state.apifields.end_date,
                shift_id: state.apifields.shift_id,
                h_display_id: state.apifields.h_display_id,
                ward_id: state.apifields.ward_id,
                call_id: state.apifields.call_id,
                is_escalated: state.apifields.is_escalated

            };
            // Convert the filtered parameters into a query string
            // Filter out null or blank parameters
            const filteredParams = Object.fromEntries(
                Object.entries(queryParams).filter(([_, value]) => value != null && value !== '')
            );
            const queryString = new URLSearchParams(filteredParams).toString();
            // Construct the final apiUrl
            const newApiUrl = `report/calllog?${queryString}`;
            setApiUrl(newApiUrl);

            if (!state.show_filter || !state.filter_applied) {
                return;
            }
            if (queryString && queryString != `page=1&limit=${limit}`) {
            } else {
                showToast("Please enter/select at least one filter criteria to apply the filter.");
            }
        }
    }, [tblhasrights, state.apifields, state.show_filter, page, limit]);
    const { data, error, isValidating } = useSWR(
        apiUrl,
        () => getAPI(apiUrl, false),
        {
            revalidateOnReconnect: false,
            revalidateOnFocus: false,
        }
    );
    tbldata = data;

    //#region ====================================================== shift Dropdown Bind ======================================================
    useEffect(() => {

        if (state.show_filter && state.call_api == true && tblshift.length == 0) {
            const fetchData = async () => {
                const { data: shiftdata, errorshift, isValidatingshift } = await getAPI(apiShift, false);
                setTblShift(shiftdata);
                //console.log(tblshift);
                //  tblshift = shiftdata;
            };
            fetchData();
        }
        if (state.show_filter && state.call_api == true && state.fields.hospital_id != "" && state.fields.hospital_id != "0") {
            const fetchData = async () => {
                const { data: warddata, errorward, isValidatingward } = await getAPI(apiWard + "&hospital_id=" + state.fields.hospital_id, false);
                setTblward(warddata);
                //  tblshift = shiftdata;
            };
            fetchData();
            setState((prevState) => ({
                ...prevState,
                call_api: false
            }));

        }
        if (state.show_filter && state.call_api == true && state.fields.hospital_id != "" && state.fields.hospital_id != "0") {
            const fetchData = async () => {
                const { data: displaydata, errordisplay, isValidatingdisplay } = await getAPI(apiDisplay + "&hospital_id=" + state.fields.hospital_id, false);
                setTbldisplay(displaydata);
                //  tblshift = shiftdata;
            };
            fetchData();
        }
        if (state.show_filter && state.call_api == true && tblcall.length == 0) {
            const fetchData = async () => {
                const { data: calldata, errorcall, isValidatingcall } = await getAPI(apiCall, false);
                setTblcall(calldata);
                //  tblshift = shiftdata;
            };
            fetchData();
        }
    }, [state.show_filter, state.call_api]);
    // const { data: shiftdata, errorshift, isValidatingshift } = useSWR(
    //     apiShift,
    //     () => getAPI(apiShift, false),
    //     {
    //         revalidateOnReconnect: false,
    //         revalidateOnFocus: false,
    //     }
    // );
    // tblshift = shiftdata;
    //#endregion
    //#region ====================================================== Pagination & Page Changed ======================================================
    useEffect(() => {
        try {
            if (tbldata && tbldata.data && tbldata.data.hasOwnProperty("count") && tbldata.data.count > 0) {
                let dblPageCount = Math.ceil(parseInt(tbldata.data.count) / limit);
                let pageCount = Math.max(1, dblPageCount);
                updateState("total_pages", pageCount);
                let rows = [];
                if (pageCount > 0) {
                    for (let i = 1; i <= pageCount; i++) {
                        if (i == 1 || i == pageCount) {
                            rows.push({ text: i.toString(), value: i.toString(), enabled: i != parseInt(page) });
                        }
                        else if ((i >= parseInt(page) - 2) && (i <= parseInt(page) + 3)) {
                            rows.push({ text: i.toString(), value: i.toString(), enabled: i != parseInt(page) });
                        }
                        else if (((i > parseInt(page) + 3) && (i >= pageCount - 2))) {
                            rows.push({ text: i.toString(), value: i.toString(), enabled: i != parseInt(page) });
                        }
                    }
                } else {
                    pageCount = '0';
                }
                setState((prevState) => ({
                    ...prevState,
                    pagination: rows
                }));
                tblpagination = rows;
                let firstpage = 1;
                let lastpage = 1;
                if (parseInt(page) === 1) {
                    if (limit * parseInt(page) <= parseInt(tbldata.data.count)) {
                        firstpage = 1;
                        lastpage = limit;
                    } else {
                        firstpage = 1;
                        lastpage = parseInt(tbldata.data.count);
                    }
                } else {
                    if (limit * parseInt(page) <= parseInt(tbldata.data.count)) {
                        firstpage = limit * (parseInt(page) - 1) + 1;
                        lastpage = limit * parseInt(page);
                    } else {
                        lastpage = parseInt(tbldata.data.count);
                        firstpage = limit * (parseInt(page) - 1) + 1;
                    }
                }
                updateState("lblpagedisplay", tbldata.data.count !== '0' ? `${firstpage} - ${lastpage}` : '0 - 0');
                if (lastpage === 0) {
                    firstpage = 0;
                    updateState("lblpagedisplay", tbldata.data.count !== '0' ? `${firstpage} - ${lastpage}` : '0 - 0');
                }
            }
        } catch (ex) {
        }
    }, [data]);
    const handlePageChange = (newPage) => {
        setPage(newPage);
    };
    //#endregion
    const formatDuration = (durationInSeconds) => {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = Math.round(durationInSeconds % 60);

        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    };
    const pad = (number) => {
        return number.toString().padStart(2, '0');
    };
    //#region ====================================================== Filter Section ======================================================
    const applyFilter = async () => {
        try {
            setState((prevState) => ({
                ...prevState,
                filter_applied: true,
                apifields: {
                    ...prevState.apifields,
                    hospital_id: prevState.fields.hospital_id,
                    start_date: prevState.fields.start_date,
                    end_date: prevState.fields.end_date,
                    shift_id: prevState.fields.shift_id,
                    h_display_id: prevState.fields.h_display_id,
                    ward_id: prevState.fields.ward_id,
                    call_id: prevState.fields.call_id,
                    is_escalated: prevState.fields.is_escalated
                },
            }));
            // //console.log(setState);
            setPage(1);
        } catch (err) { }
    };
    const clearFilter = async () => {
        try {
            const updatedapifieldsState = {
                hospital_id: state.fields.hospital_id,
                shift_id: "",
                h_display_id: "",
                ward_id: "",
                call_id: "",
                is_escalated: "",
                start_date: "",
                end_date: ""
            };
            const updatedfieldState = {
                hospital_id: state.fields.hospital_id,
                shift_id: "",
                h_display_id: "",
                ward_id: "",
                call_id: "",
                is_escalated: "",
                start_date: "",
                end_date: ""
            };
            updateState("apifields", updatedapifieldsState);
            updateState("fields", updatedfieldState);
            updateState("filter_applied", false);
        } catch (err) { }
    };
    const showFilter = async () => {
        try {
            if (state.fields.hospital_id != "" && state.fields.hospital_id != "0") {
                updateState("show_filter", true);
            }
            else {
                showToast("Please select hospital first");
            }

        } catch (err) { }
    };
    const hideFilter = async () => {
        try {
            updateState("show_filter", false);
        } catch (err) { }
    };
    //#endregion
    //#region ====================================================== Multi Select2 Dropdown ======================================================
    const handleSelectMultipleChange = (event) => {
        const selectedOptions = Array.from(
            event.target.selectedOptions,
            (option) => option.value
        );
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                shift_id: selectedOptions,
            },
        }));
    };
    //#endregion
    //#region ====================================================== Multi Select2 Dropdown ======================================================
    const handleSelectMultipleChangedisplay = (event) => {
        const selectedOptions = Array.from(
            event.target.selectedOptions,
            (option) => option.value
        );
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                h_display_id: selectedOptions,
            },
        }));
    };
    //#endregion
    //#region ====================================================== Multi Select2 Dropdown ======================================================
    const handleSelectMultipleChangeward = (event) => {
        const selectedOptions = Array.from(
            event.target.selectedOptions,
            (option) => option.value
        );
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                ward_id: selectedOptions,
            },
        }));
    };
    //#endregion
    //#region ====================================================== Multi Select2 Dropdown ======================================================
    const handleSelectMultipleChangecall = (event) => {
        const selectedOptions = Array.from(
            event.target.selectedOptions,
            (option) => option.value
        );
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                call_id: selectedOptions,
            },
        }));
    };
    //#endregion


    //#region ====================================================== hospital Dropdown Bind ======================================================
    const { data: hospitaldata, errorhospital, isValidatinghospital } = useSWR(
        apiHospital,
        () => getAPI(apiHospital, false),
        {
            revalidateOnReconnect: false,
            revalidateOnFocus: false,
        }
    );
    tblhospitals = hospitaldata;
    //#endregion

    //#region ====================================================== Toast Message set ======================================================
    const showToast = (message) => {
        updateState("show_toast", true);
        updateState("toast_msg", message);
        setTimeout(() => {
            updateState("show_toast", false);
        }, 5000);
    };
    const hideToast = () => {
        updateState("show_toast", false);
    };
    //#endregion

    //#region ====================================================== Get API ======================================================
    const getAPI = async (url, Auth) => {
        try {
            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                if (Auth) {
                    url += `&user_id=${getUser.user_id}`;
                }
                const response = await axios.get(`${baseURL.serverUrl}${url}`, {
                    headers: {
                        "x-access-token": getUser.token,
                        Authorization: `Bearer ${getUser.token}`,
                    },
                });
                //console.log(response.data)
                return response.data;
            } else {
                return {};
            }
        } catch (err) {
            return {};
        }
    };
    //#endregion  
    function convertToAMPM(time) {
        var hours = parseInt(time);
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert to 12-hour format
        return hours + ' ' + ampm;
    }
    //#region ====================================================== Date validation ======================================================
    const handleDateChange = (event) => {
        const { name, value } = event.target;
        const { start_date, end_date } = state.fields;
        const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(value);
        if (!isValidDate) {
            showToast("Invalid date format");
            return;
        }
        if (name === "start_date" && new Date(value) > new Date(end_date)) {
            showToast("Start date cannot be greater than end date");
            return;
        }
        if (name === "end_date" && new Date(value) < new Date(start_date)) {
            showToast("End date cannot be less than start date");
            return;
        }
        // Update the state
        setState((prevState) => ({
            ...prevState,
            fields: { ...prevState.fields, [name]: value },
        }));
    };
    //#endregion
    //#region ====================================================== Export File ======================================================
    function modifyUrl(apiUrl, start_date, end_date, is_all, getUser) {
        let url = new URL(`export/${apiUrl}`, baseURL.serverUrl);
        // Remove "page" and "limit" if they exist
        url.searchParams.delete("page");
        url.searchParams.delete("limit");
        // If is_all, remove "start_date" and "end_date"
        if (is_all) {
            url.searchParams.delete("start_date");
            url.searchParams.delete("end_date");
        } else {
            // If not is_all, remove existing "start_date" and "end_date"
            url.searchParams.delete("start_date");
            url.searchParams.delete("end_date");
            // Add new "start_date" and "end_date"
            if (start_date && end_date) {
                url.searchParams.append("start_date", start_date);
                url.searchParams.append("end_date", end_date);
            }
        }
        // Check if the URL already has a "token" parameter
        if (!url.searchParams.has("token") && getUser) {
            // If not, add the "token" parameter
            url.searchParams.append("token", getUser.token);
        }
        return url.toString();
    }
    const downloadFile = async (start_date, end_date, is_all) => {
        try {
            //  let apiUrluser = `export/${apiUrl}`;
            let getUser = localStorage.getItem('user');
            if (getUser) {
                getUser = JSON.parse(getUser);
                let downloadURL = modifyUrl(apiUrl, start_date, end_date, is_all, getUser);
                // Fetch the file data
                const response = await fetch(`${downloadURL}`);
                const blob = await response.blob();
                // Create a Blob URL
                const blobUrl = window.URL.createObjectURL(blob);
                // Create a download link
                const link = document.createElement('a');
                link.href = blobUrl;
                const currentDate = new Date();
                const formattedDateTime = currentDate.toLocaleString().replace(/[/\s,:]/g, ''); // Remove special characters from the datetime
                const filename = `users_${formattedDateTime}.xlsx`; // Concatenate the formatted datetime to the filename
                link.download = filename;
                //link.download = 'filename.xlsx'; // Set the desired filename here
                // Append the link to the document
                document.body.appendChild(link);
                // Simulate a click on the link to trigger the download
                link.click();
                // Remove the link from the document
                document.body.removeChild(link);
                // Clean up the Blob URL
                window.URL.revokeObjectURL(blobUrl);
            }
        } catch (error) {
            console.error(error);
        }
    };
    // Helper functions to get start and end dates
    const todayStartDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };
    const todayEndDate = () => todayStartDate();
    const weekStartDate = () => {
        const today = new Date();
        const weekStart = new Date(today);
        weekStart.setDate(today.getDate() - 7);
        return weekStart.toISOString().split('T')[0];
    };
    const weekEndDate = () => todayEndDate();
    const monthStartDate = () => {
        const today = new Date();
        const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        return monthStart.toISOString().split('T')[0];
    };
    const monthEndDate = () => todayEndDate();
    const yearStartDate = () => {
        const today = new Date();
        const yearStart = new Date(today.getFullYear(), 0, 1);
        return yearStart.toISOString().split('T')[0];
    };
    const yearEndDate = () => todayEndDate();
    //#endregion

    //#region ====================================================== Checkbox Handler ======================================================
    const handleCheckboxClick = () => {
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                is_escalated: !prevState.fields.is_escalated,
            },
        }), () => {
            //console.log(state);
        });
    };
    //#endregion
    // Convert seconds to "hh:mm:ss" format
    function secondsToHHMMSSsum(secondstotal) {

        const codeBlueAvg = parseInt(secondstotal.codeblueavg || 0);
        const extraHelpAvg = parseInt(secondstotal.extrahelpavg || 0);
        const houseCallAvg = parseInt(secondstotal.housecallavg || 0);
        const nurseCallAvg = parseInt(secondstotal.nursecallavg || 0);
        const toiletCallAvg = parseInt(secondstotal.toiletcallavg || 0);

        // Calculate total call average for non-zero averages
        const totalCallAvgValues = [codeBlueAvg, extraHelpAvg, houseCallAvg, nurseCallAvg, toiletCallAvg].filter(avg => avg > 0);
        const totalCallAvg = totalCallAvgValues.length > 0 ? totalCallAvgValues.reduce((acc, curr) => acc + curr) / totalCallAvgValues.length : 0;
        let seconds = totalCallAvg;

        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    }

    function formatTime(timeString) {
        // Convert timeString to a Date object
        const time = new Date(`1970-01-01T${timeString}`);

        // Get the hours and minutes in 12-hour format
        const hours = time.getHours() % 12 || 12; // Handle midnight (0 hours)
        const minutes = time.getMinutes();

        // Determine if it's AM or PM
        const period = time.getHours() < 12 ? 'AM' : 'PM';

        // Format the time with leading zeros and AM/PM indicator
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;

        return formattedTime;
    }


    function sumtime(secondstotal) {

        const codeBlueAvg = parseInt(secondstotal.codeblueavg || 0);
        const extraHelpAvg = parseInt(secondstotal.extrahelpavg || 0);
        const houseCallAvg = parseInt(secondstotal.housecallavg || 0);
        const nurseCallAvg = parseInt(secondstotal.nursecallavg || 0);
        const toiletCallAvg = parseInt(secondstotal.toiletcallavg || 0);

        // Calculate total call average for non-zero averages
        const totalCallAvgValues = [codeBlueAvg, extraHelpAvg, houseCallAvg, nurseCallAvg, toiletCallAvg].filter(avg => avg > 0);
        const totalCallAvg = totalCallAvgValues.length > 0 ? totalCallAvgValues.reduce((acc, curr) => acc + curr) / totalCallAvgValues.length : 0;
        let seconds = totalCallAvg;
        return seconds;
    }
    function secondsToHHMMSS(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    }
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    // New CODE
    const getTotalCount = (counts) => {
        const value = parseInt(counts.codebluecount || 0) +
            parseInt(counts.toiletcallcount || 0) +
            parseInt(counts.extrahelpcount || 0) +
            parseInt(counts.nursecallcount || 0) +
            parseInt(counts.housecallcount || 0);

        return value;
    };
    const calculatePercentage = (value1, value2) => {
        let calPer = '';
        if (value1 - value2 > 0) {
            if (value2 === 0) {
            } else {
                calPer = ((value1 - value2) * 100 / value2).toString();
            }
        } else {
            if (value1 === 0) {
            } else {
                calPer = ((value1 - value2) * 100 / value1).toString();
            }
        }
        return calPer;
    };
    useEffect(() => {
        if (
            tblhospitals &&
            tblhospitals.data &&
            tblhospitals.data.hasOwnProperty("hospitals") &&
            tblhospitals.data.hospitals.length == 1
        ) {
            setState((prevState) => ({
                ...prevState,
                fields: {
                    ...prevState.fields,
                    hospital_id: tblhospitals.data.hospitals[0].hospital_id,
                },
            }));
        }
    }, [tblhospitals]);
    return (
        <>
            {tblhasrights &&
                tblhasrights.data &&
                Array.isArray(tblhasrights.data.hasrights) &&
                tblhasrights.data.hasrights.length > 0 &&
                tblhasrights.data.hasrights[0].hasOwnProperty("view_status") &&
                tblhasrights.data.hasrights[0]["view_status"] && (
                    <>
                        <div className="row g-gs">
                            <div className="col-xxl-12">
                                <div className="card h-100">
                                    <div className="card-body boxpadding">
                                        <div className="card-title-group mb-4 align-items-start">
                                            <div className="card-title">
                                                <h4 className="title mb-0 lineheight0">Calls History</h4>
                                                <span className="small subheader">Get an at-a-glance view of key information and statistics for a comprehensive overview of the hospital's calls and average duration.</span>
                                            </div>
                                            <div className="card-tools">

                                                <div className="btn-group" onMouseEnter={() => setIsDropdownOpen(true)} onMouseLeave={() => setIsDropdownOpen(false)}>
                                                    <button className="btn btn-outline-light btn-md d-md-inline-flex mr10 dropdown-toggle" type="button" id="dropdownMenuButton1" aria-expanded="false" onClick={toggleDropdown}> <em className="icon ni ni-download"></em>
                                                        <span>Export</span>
                                                    </button>
                                                    <ul className={`dropdown-menu dropdown-menu-end setbuttonhover ${isDropdownOpen ? 'show' : ''}`}>
                                                        <li>
                                                            <button
                                                                type="button"
                                                                className="dropdown-item"
                                                                onClick={() => downloadFile(todayStartDate(), todayEndDate(), false)}
                                                            >
                                                                <span>Todays Records</span>
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button
                                                                type="button"
                                                                className="dropdown-item"
                                                                onClick={() => downloadFile(weekStartDate(), weekEndDate(), false)}
                                                            >
                                                                <span>This Week's Records</span>
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button
                                                                type="button"
                                                                className="dropdown-item"
                                                                onClick={() => downloadFile(monthStartDate(), monthEndDate(), false)}
                                                            >
                                                                <span>This Month's Records</span>
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button
                                                                type="button"
                                                                className="dropdown-item"
                                                                onClick={() => downloadFile(yearStartDate(), yearEndDate(), false)}
                                                            >
                                                                <span>This Year's Records</span>
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button
                                                                type="button"
                                                                className="dropdown-item"
                                                                onClick={() => downloadFile(null, null, true)}
                                                            >
                                                                <span>All Records</span>
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>

                                                {!state.show_filter && (
                                                    <>
                                                        <button
                                                            type="button"
                                                            className="btn btn-color-dark d-none d-md-inline-flex  mr10"
                                                            name="btnshowfilter"
                                                            onClick={(e) => showFilter()}
                                                        >
                                                            <em className="icon ni ni-filter"></em>
                                                            <span>Show Filter</span>
                                                        </button>
                                                    </>
                                                )}

                                            </div>
                                        </div>
                                        <div className="row g-gs">
                                            {showHospital ? (<div className="col-lg-3">
                                                <div className="form-group">
                                                    <Select2
                                                        data={[
                                                            ...(tblhospitals && tblhospitals.data && tblhospitals.data.hasOwnProperty("hospitals") && tblhospitals.data.hospitals || []).map((x) => ({
                                                                text: x.hospital_name,
                                                                id: x.hospital_id,
                                                                key: x.hospital_id.toString(),
                                                            })),
                                                        ]}
                                                        options={{
                                                            placeholder: "Select hospital",
                                                        }}
                                                        name="hospital_id"
                                                        className="form-control"
                                                        value={state.fields.hospital_id}
                                                        onChange={handleInputChangeHospital}
                                                    />
                                                </div>
                                            </div>) : <></>
                                            }
                                            {/* <div className="col-lg-3">
                                                <div className="form-group">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary d-none d-md-inline-flex mr10"
                                                        name="btnfilter"
                                                        onClick={(e) => applyFilter()}
                                                    >
                                                        <em className="icon ni ni-search"></em>
                                                        <span>Preview</span>
                                                    </button>
                                                </div>
                                            </div> */}
                                            <div className="col-lg-6 titlealign">
                                                {tbldata && tbldata.data && tbldata.data ? (
                                                    <>
                                                        <div class="small titlesubcolor">
                                                            {/* <div>
                                                                        {tbldata.data.devicetitle}
                                                                    </div> */}
                                                            <div>
                                                                {tbldata.data.devicedescription}
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className="norecordcss1">
                                                    </div>
                                                )}
                                            </div>
                                            {/* ====================================================== Filter section ====================================================== */}
                                            {state.show_filter && (
                                                <>
                                                    <div>
                                                        <span className="dividerlist  mt-0"></span>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <h5 className="summary">
                                                                    Filter
                                                                    <span className="float-end">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-color-danger d-none d-md-inline-flex"
                                                                            name="btnhidefilter"
                                                                            onClick={(e) => hideFilter()}
                                                                        >
                                                                            <em className="icon ni ni-cross"></em>
                                                                            <span>Hide filter</span>
                                                                        </button>
                                                                    </span>
                                                                </h5>
                                                                <p className="ptitle">
                                                                    Refine your search effortlessly with our advanced
                                                                    filtering options.
                                                                </p>
                                                            </div>
                                                            <div className="col-md-12 mt-0">
                                                                <div className="row">
                                                                    <div className="col-lg-3">
                                                                        <div className="form-group">
                                                                            <label className="form-label">
                                                                                Shift
                                                                            </label>
                                                                            <Select2
                                                                                multiple
                                                                                data={[
                                                                                    ...(tblshift && tblshift.hasOwnProperty("shift") && tblshift.shift || []).map((x) => ({
                                                                                        text: x.shift_name,
                                                                                        id: x.shift_id,
                                                                                        key: x.shift_id.toString(),
                                                                                    })),
                                                                                ]}
                                                                                options={{
                                                                                    placeholder: "select multiple option",
                                                                                }}
                                                                                name="shifts"
                                                                                className="form-control"
                                                                                value={state.fields.shift_id}
                                                                                onChange={handleSelectMultipleChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3">
                                                                        <div className="form-group">
                                                                            <label className="form-label">
                                                                                Nurse station
                                                                            </label>
                                                                            <Select2
                                                                                multiple
                                                                                data={[
                                                                                    ...(tbldisplay && tbldisplay.hasOwnProperty("displaylist") && tbldisplay.displaylist || []).map((x) => ({
                                                                                        text: x.display_name,
                                                                                        id: x.h_display_id,
                                                                                        key: x.h_display_id.toString(),
                                                                                    })),
                                                                                ]}
                                                                                options={{
                                                                                    placeholder: "select multiple option",
                                                                                }}
                                                                                name="displays"
                                                                                className="form-control"
                                                                                value={state.fields.h_display_id}
                                                                                onChange={handleSelectMultipleChangedisplay}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-3">
                                                                        <div className="form-group">
                                                                            <label className="form-label">
                                                                                Ward
                                                                            </label>
                                                                            <Select2
                                                                                multiple
                                                                                data={[
                                                                                    ...(tblward && tblward.hasOwnProperty("wardlist") && tblward.wardlist || []).map((x) => ({
                                                                                        text: x.ward_name,
                                                                                        id: x.ward_id,
                                                                                        key: x.ward_id.toString(),
                                                                                    })),
                                                                                ]}
                                                                                options={{
                                                                                    placeholder: "select multiple option",
                                                                                }}
                                                                                name="wards"
                                                                                className="form-control"
                                                                                value={state.fields.ward_id}
                                                                                onChange={handleSelectMultipleChangeward}
                                                                            />
                                                                        </div>
                                                                    </div>


                                                                    <div className="col-lg-3">
                                                                        <div className="form-group">
                                                                            <label className="form-label">
                                                                                Call type
                                                                            </label>
                                                                            <Select2
                                                                                multiple
                                                                                data={[
                                                                                    ...(tblcall && tblcall.hasOwnProperty("call") && tblcall.call || []).map((x) => ({
                                                                                        text: x.call_name,
                                                                                        id: x.call_id,
                                                                                        key: x.call_id.toString(),
                                                                                    })),
                                                                                ]}
                                                                                options={{
                                                                                    placeholder: "select multiple option",
                                                                                }}
                                                                                name="calls"
                                                                                className="form-control"
                                                                                value={state.fields.call_id}
                                                                                onChange={handleSelectMultipleChangecall}
                                                                            />
                                                                        </div>
                                                                    </div>


                                                                    <div className="col-md-3">
                                                                        <div className="form-group">
                                                                            <label className="form-label">
                                                                                Escalated Only
                                                                            </label>
                                                                            <div className="form-control-wrap">
                                                                                <div className="form-check form-switch">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="checkbox"
                                                                                        checked={state.fields.is_escalated}
                                                                                        onChange={handleCheckboxClick}
                                                                                        value=""
                                                                                    />


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <div className="form-group">
                                                                            <label className="form-label">
                                                                                Start date
                                                                            </label>
                                                                            <div className="form-control-wrap">
                                                                                <input
                                                                                    type="date"
                                                                                    name="start_date"
                                                                                    className="form-control"
                                                                                    value={state.fields.start_date}
                                                                                    onChange={handleDateChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <div className="form-group">
                                                                            <label className="form-label">
                                                                                End date
                                                                            </label>
                                                                            <div className="form-control-wrap">
                                                                                <input
                                                                                    type="date"
                                                                                    name="end_date"
                                                                                    className="form-control"
                                                                                    value={state.fields.end_date}
                                                                                    onChange={handleDateChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-primary d-none d-md-inline-flex mr10"
                                                                                name="btnfilter"
                                                                                onClick={(e) => applyFilter()}
                                                                            >
                                                                                <em className="icon ni ni-search"></em>
                                                                                <span>Filter</span>
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-color-info d-none d-md-inline-flex"
                                                                                name="btnclearfilter"
                                                                                onClick={(e) => clearFilter()}
                                                                            >
                                                                                <em className="icon ni ni-delete"></em>
                                                                                <span>Clear filter</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <span className="dividerlist mt-4 mb-4"></span>
                                                    </div>
                                                </>
                                            )}
                                            {/* ====================================================== Table data ====================================================== */}
                                            {tblhasrights &&
                                                tblhasrights.data &&
                                                Array.isArray(tblhasrights.data.hasrights) &&
                                                tblhasrights.data.hasrights.length > 0 &&
                                                tblhasrights.data.hasrights[0].hasOwnProperty("view_status") &&
                                                tblhasrights.data.hasrights[0]["view_status"] && (
                                                    <>
                                                        {tbldata &&
                                                            (!tbldata.data || !tbldata.data.calllog?.length) ? (
                                                            <>
                                                                <div className="norecordcss">
                                                                    <img
                                                                        src={emptyrecord}
                                                                        width="330px"
                                                                        alt="No record found"
                                                                    />
                                                                    <div className="norecordfont">No record found</div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <table
                                                                    className="datatable-init table"
                                                                    data-nk-container="table-responsive"
                                                                >
                                                                    <thead className="table-light">
                                                                        <tr>
                                                                            <th className="tb-col">
                                                                                <span className="overline-title">Basic Info</span>
                                                                            </th>
                                                                            <th className="tb-col">
                                                                                <span className="overline-title">Ward</span>
                                                                            </th>
                                                                            <th className="tb-col">
                                                                                <span className="overline-title">Bed</span>
                                                                            </th>
                                                                            <th className="tb-col">
                                                                                <span className="overline-title">Call</span>
                                                                            </th>
                                                                            <th className="tb-col">
                                                                                <span className="overline-title">Started On</span>
                                                                            </th>
                                                                            <th className="tb-col">
                                                                                <span className="overline-title">Duration</span>
                                                                            </th>
                                                                            <th className="tb-col">
                                                                                <span className="overline-title">Type</span>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {tbldata &&
                                                                            tbldata.data.calllog.map((x) => (
                                                                                <tr key={x?.log_id}>
                                                                                    <td className="tb-col">
                                                                                        <a
                                                                                            href="#"
                                                                                        >
                                                                                            <div className="media-group">
                                                                                                <div className="media-text">
                                                                                                    <span className="title">
                                                                                                        {x.report_name}
                                                                                                    </span>
                                                                                                    <span className="small text">{x?.display_name}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </a>
                                                                                    </td>
                                                                                    <td className="tb-col">{x?.ward_name}</td>
                                                                                    <td className="tb-col">{x?.bed_name}</td>
                                                                                    <td className="tb-col">{x?.call_name}</td>
                                                                                    <td className="tb-col">
                                                                                        {x?.call_start ? formatTime(x.call_start) : "N/A"}
                                                                                    </td>

                                                                                    <td className="tb-col">{x?.call_duration}</td>
                                                                                    <td className="tb-col">{x.call_type_name}</td>
                                                                                </tr>
                                                                            ))}
                                                                    </tbody>
                                                                </table>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            {/* ====================================================== Pagination ====================================================== */}
                                            {tblhasrights &&
                                                tblhasrights.data &&
                                                Array.isArray(tblhasrights.data.hasrights) &&
                                                tblhasrights.data.hasrights.length > 0 &&
                                                tblhasrights.data.hasrights[0].hasOwnProperty("view_status") &&
                                                tblhasrights.data.hasrights[0]["view_status"] && (
                                                    <>
                                                        {tbldata && tbldata.data && tbldata.data.calllog?.length && (
                                                            <>
                                                                <div className="divbottom">
                                                                    <div className="bottombutton1">
                                                                        <div className="dataTable-info"> Showing {state.lblpagedisplay} of {tbldata.data.count} records </div>
                                                                        <nav className="paginations">
                                                                            <ul className="pagination pagination-s1 flex-wrap">
                                                                                {state.pagination && state.pagination.length && state.pagination.map((x) => (
                                                                                    <>
                                                                                        <div className="pagination-pedding" key={x.value}>
                                                                                            <li className={`page-item ${x.enabled ? '' : 'active'}`}>
                                                                                                <button className="page-link" onClick={() => handlePageChange(x.value)}>
                                                                                                    {x.text}
                                                                                                </button>
                                                                                            </li>
                                                                                        </div>
                                                                                    </>
                                                                                ))}
                                                                            </ul>
                                                                        </nav>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </>)}

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        {/* ====================================================== Toast message ====================================================== */}
                        {state.show_toast && (
                            <div
                                className="toast align-items-center text-bg-primary border-0 fade show toastmsg"
                                role="alert"
                                aria-live="assertive"
                                aria-atomic="true"
                            >
                                <div className="d-flex">
                                    <div className="toast-body">{state.toast_msg}</div>
                                    <button
                                        type="button"
                                        className="btn-close btn-close-white me-2 m-auto"
                                        data-bs-dismiss="toast"
                                        onClick={(e) => hideToast()}
                                        aria-label="Close"
                                    ></button>
                                </div>
                            </div>
                        )}
                    </>
                )}
        </>
    );

};


export default adminLayout(CallLog);