import React, { useState, useEffect, useCallback } from "react";
import adminLayout from "../hoc/adminLayout";
import Chart from "react-apexcharts";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import nurse_icon from "../images/icon/nurse_icon.svg";
import code_blue from "../images/icon/code_blue.svg";
import escalation_icon from "../images/icon/escalation_icon.svg";
import toilet_icon from "../images/icon/toilet_icon.svg";
import help_icon from "../images/icon/help_icon.svg";
import house_icon from "../images/icon/house_icon.svg";
import emptyrecord from "../assets/images/empty.png";
import logoimage from "../assets/images/siscall.png";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import debounce from "lodash/debounce";
import baseURL from "../common/base-urls.js";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { useNavigate, useLocation } from "react-router-dom";

const Dashboard = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [apiUrl, setApiUrl] = useState('');
    const [page, setPage] = useState(1);
    const [showHospital, setShowHospital] = useState(true);
    const [limit, setLimit] = useState(3);
    const [highfreq, sethighfreq] = useState([]);
    const [optionhighfreq, setoptionhighfreq] = useState([]);
    const [busyhour, setbusyhour] = useState([]);
    const [optionbusyhour, setoptionbusyhour] = useState([]);
    const [xnurse, setxnurse] = useState([]);
    const [optionxnurse, setoptionxnurse] = useState([]);
    const [tblshift, setTblShift] = useState([]);
    const [tbldisplay, setTbldisplay] = useState([]);
    const [tblward, setTblward] = useState([]);
    const [tblcall, setTblcall] = useState([]);
    //#region ====================================================== Create State =====================================================
    const resetStateVariables = () => {
        setApiUrl('');
        setPage(1);
        setLimit(3);
        sethighfreq([]);
        setoptionhighfreq([]);
        setbusyhour([]);
        setoptionbusyhour([]);
        setxnurse([]);
        setoptionxnurse([]);
        setTblShift([]);
        setTbldisplay([]);
        setTblward([]);
        setTblcall([]);
        // Add any additional state variables here
    };
    useEffect(() => {
        resetStateVariables();
    }, [location.pathname]);
    const [state, setState] = useState({
        pagination: [],
        show_toast: false,
        toast_msg: "No record found",
        show_modal: false,
        filter_applied: false,
        show_filter: false,
        modal_title: "Confirmation",
        call_api: false,
        send_alert: false,
        modal_msg:
            "Are you absolutely certain you want to delete this record? This action cannot be reversed.",
        modal_button: "Delete",
        fields: {
            hospital_id: "",
            start_date: "",
            end_date: "",
            shift_id: "",
            display_id: "",
            ward_id: "",
            call_id: "",
            is_escalated: ""
        },
        apifields: {
            hospital_id: "",
            start_date: "",
            end_date: "",
            shift_id: "",
            display_id: "",
            ward_id: "",
            call_id: "",
            is_escalated: ""
        },
    });
    //#endregion
    //#region ====================================================== Input Hospital ======================================================
    const handleInputChangeHospital = (event) => {
        const { name, value } = event.target;
        setState((prevState) => ({
            ...prevState,
            call_api: true,
            fields: {
                ...prevState.fields,
                [name]: value,
            },
            apifields: {
                ...prevState.fields,
                [name]: value,
            },
        }));
    };
    //#endregion
    useEffect(() => {
        const currentTime = new Date();
        const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        // //console.log(`=======================================`);
        // //console.log(`========= Updated on ${formattedTime} =========`);
        // //console.log(`=======================================`);
        // //console.log(location);
        resetStateVariables();
        let startDate = new Date(currentTime);
        let endDate = new Date(currentTime);
        let gethospitalid = localStorage.getItem("hospital_id");
        let hospitalid = null; // Example value
        if (gethospitalid) {
            hospitalid = gethospitalid;
        }
        // //console.log("hospitalid:", hospitalid, typeof hospitalid);
        setState((prevState) => ({
            ...prevState,
            fields: {
                hospital_id: "",
                start_date: "",
                end_date: "",
                shift_id: "",
                display_id: "",
                ward_id: "",
                call_id: "",
                is_escalated: ""
            },
            apifields: {
                hospital_id: "",
                start_date: "",
                end_date: "",
                shift_id: "",
                display_id: "",
                ward_id: "",
                call_id: "",
                is_escalated: ""
            }
        }));

        // Check if hospitalid is the string "null"
        if (hospitalid !== "null" && hospitalid !== "0") {
            setShowHospital(false);
            setState((prevState) => ({
                ...prevState,
                fields: {
                    ...prevState.fields,
                    hospital_id: hospitalid,
                },
                apifields: {
                    ...prevState.fields,
                    hospital_id: hospitalid,
                },
            }));
        }

        if (location.pathname.includes('/dashboard/weekly')) {
            startDate.setDate(startDate.getDate() - startDate.getDay());
            endDate.setDate(endDate.getDate() + (6 - endDate.getDay()));

            setState(prevState => ({
                ...prevState,
                fields: {
                    ...prevState.fields,

                    start_date: startDate.toISOString().split('T')[0],
                    end_date: endDate.toISOString().split('T')[0]
                }
            }));
        } else if (location.pathname.includes('/dashboard/daywise')) {
            startDate = new Date(currentTime);
            endDate = new Date(currentTime);
            setState(prevState => ({
                ...prevState,
                fields: {
                    ...prevState.fields,

                    start_date: startDate.toISOString().split('T')[0],
                    end_date: endDate.toISOString().split('T')[0]
                }
            }));
        } else if (location.pathname.includes('/dashboard/monthly')) {
            startDate.setDate(1);
            endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
            setState(prevState => ({
                ...prevState,
                fields: {
                    ...prevState.fields,

                    start_date: startDate.toISOString().split('T')[0],
                    end_date: endDate.toISOString().split('T')[0]
                }
            }));
        } else if (location.pathname.includes('/dashboard/quarterly')) {
            const currentMonth = startDate.getMonth();
            const quarterStartMonth = currentMonth - (currentMonth % 3);
            startDate = new Date(startDate.getFullYear(), quarterStartMonth, 1);
            endDate = new Date(startDate.getFullYear(), quarterStartMonth + 3, 0);
            setState(prevState => ({
                ...prevState,
                fields: {
                    ...prevState.fields,

                    start_date: startDate.toISOString().split('T')[0],
                    end_date: endDate.toISOString().split('T')[0]
                }
            }));
        } else if (location.pathname.includes('/dashboard/halfyearly')) {
            const currentMonth = startDate.getMonth();
            if (currentMonth < 6) {
                startDate.setMonth(0);
                endDate.setMonth(5);
            } else {
                startDate.setMonth(6);
                endDate.setMonth(11);
            }
            startDate.setDate(1);
            endDate.setDate(new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate());
            setState(prevState => ({
                ...prevState,
                fields: {
                    ...prevState.fields,

                    start_date: startDate.toISOString().split('T')[0],
                    end_date: endDate.toISOString().split('T')[0]
                }
            }));
        } else if (location.pathname.includes('/dashboard/yearly')) {
            startDate.setMonth(0);
            startDate.setDate(1);
            endDate.setMonth(11);
            endDate.setDate(31);
            setState(prevState => ({
                ...prevState,
                fields: {
                    ...prevState.fields,

                    start_date: startDate.toISOString().split('T')[0],
                    end_date: endDate.toISOString().split('T')[0]
                }
            }));
        }
        else if (location.pathname.includes('/dashboard/shiftwise')) {

        }
        else if (location.pathname.includes('/dashboard/displaywise')) {

        }
        else {
           
            startDate = new Date(currentTime);
            endDate = new Date(currentTime);
            setState(prevState => ({
                ...prevState,
                fields: {
                    ...prevState.fields,

                    start_date: startDate.toISOString().split('T')[0],
                    end_date: endDate.toISOString().split('T')[0]
                }
            }));
        }
       
        applyFilter();
        return () => {
        };
    }, [location]);
    //#region ====================================================== Input Textbox ======================================================
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [name]: value,
            },
        }));
    };
    //#endregion
    //#region ====================================================== Update State ======================================================
    const updateState = (key, value) => {
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };
    //#endregion
    //#region ====================================================== Page Rights ======================================================
    const formIdMap = {
        "/dashboard": 1,
        "/dashboard/daywise": 2,
        "/dashboard/weekly": 3,
        "/dashboard/monthly": 4,
        "/dashboard/quarterly": 5,
        "/dashboard/halfyearly": 6,
        "/dashboard/yearly": 7,
        "/dashboard/shiftwise": 8,
        "/dashboard/displaywise": 9
    };

    // Extract the form_url from location.pathname and get the corresponding form_id from the map
    const formUrl = location.pathname;
    //console.log(formUrl);
    const formId = formIdMap[formUrl] || 1; // Default to 1 if the form_url is not found in the map

    // Construct the rightsURL with the dynamic form_id
    const rightsURL = `user/chkuserrights?form_id=${formId}`;
    // const rightsURL = `user/chkuserrights?form_id=${location.pathname.includes('/dashboard/weekly') ? 21 : 1}`;
    const {
        data: tblhasrights,
        error: error1,
        isValidating: isValidating2,
    } = useSWR(rightsURL, () => getAPI(rightsURL, true), {
        revalidateOnReconnect: false,
        revalidateOnFocus: false,
    });
    //#endregion
    //#region ====================================================== Page Table Bind Data ======================================================
    let tbldata = {};
    let tblpagination = {};
    let tblhospitals = {};
    let apiHospital = `hospital/dropdown?page=1&limit=500`;
    let apiShift = `report/shift`;
    let apiWard = `report/wardlist?page=1&limit=100`;
    let apiDisplay = `report/display?page=1&limit=100`;
    let apiCall = `report/call?page=1&limit=50`;
    useEffect(() => {
        if (
            tblhasrights &&
            tblhasrights.data &&
            Array.isArray(tblhasrights.data.hasrights) &&
            tblhasrights.data.hasrights.length > 0 &&
            tblhasrights.data.hasrights[0].hasOwnProperty('view_status') &&
            tblhasrights.data.hasrights[0]['view_status'] && state.apifields.hospital_id != ""
        ) {
            //console.log("inside API");
            //pass hospital id and start enddate
            // const newApiUrl = `report/home?${queryString}`; 
            const queryParams = {
                hospital_id: state.apifields.hospital_id,
                start_date: state.apifields.start_date,
                end_date: state.apifields.end_date,
                shift_id: state.apifields.shift_id,
                display_id: state.apifields.display_id,
                ward_id: state.apifields.ward_id,
                call_id: state.apifields.call_id,
                is_escalated: state.apifields.is_escalated

            };
            // Filter out null or blank parameters
            const filteredParams = Object.fromEntries(
                Object.entries(queryParams).filter(([_, value]) => value != null && value !== '')
            );
            // Convert the filtered parameters into a query string
            const queryString = new URLSearchParams(filteredParams).toString();
            // Construct the final apiUrl
            const newApiUrl = `report/home?${queryString}`;
            setApiUrl(newApiUrl);
        }
    }, [tblhasrights, state.apifields, state.show_filter, page, limit]);
    const { data, error, isValidating } = useSWR(
        apiUrl,
        () => getAPI(apiUrl, false),
        {
            revalidateOnReconnect: false,
            revalidateOnFocus: false,
        }
    );
    tbldata = data;
    useEffect(() => {
        if (tbldata && tbldata.data && tbldata.data.counts_by_bt_max && tbldata.data.counts_by_bt_max.length > 0) {
            // let btNames = tbldata.data.counts_by_bt_max.map(item => item.bt_name);
            // let totalCalls = tbldata.data.counts_by_bt_max.map(item => item.total_calls);
            if (tbldata.data.counts_by_bt_max.length > 0) {
                const morningDataFormatted3 = tbldata.data.counts_by_bt_max.map((value, index) => ({
                    x: value.bt_name,
                    y: value.total_calls,
                    avg: secondsToHHMMSS(parseInt(value.total_avg)),
                    calls: value.total_calls,
                    name: 'Total calls'
                }));
                sethighfreq([
                    {
                        name: 'Total calls',
                        data: morningDataFormatted3
                    }
                ]);
                setoptionhighfreq({
                    chart: {
                        type: 'bar',
                        height: 350,
                        foreColor: "#999",
                        stacked: true,
                        dropShadow: {
                            enabled: true,
                            enabledSeries: [0],
                            top: -2,
                            left: 2,
                            blur: 5,
                            opacity: 0.06
                        }
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 4,
                            horizontal: true,
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: 2
                    },
                    colors: ['#a5cb66'],

                    grid: {
                        row: {
                            colors: ['#fff', '#f2f2f2']
                        }
                    },

                    fill: {
                        opacity: 1,
                    },
                    tooltip: {
                        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                            var data1 = w.globals.initialSeries[0].data[dataPointIndex];

                            return (
                                '<div class="card border-light chart-tooltip"> <div class="card-header theader">' + data1.x + '</div> <div class="card-body tbody "> <h5 class="card-title ttitle">' + data1.name + '</h5> <p class="tp">' + data1.avg + '<span class="tpspan "> ' + data1.calls + ' Calls</span></p> </div> </div>'
                            );


                            return (
                                ' '
                            );
                        }
                    }
                });
            }
        }
        if (tbldata && tbldata.data && tbldata.data.counts_by_hourly && tbldata.data.counts_by_hourly.length > 0) {
            // let xAxisValue = tbldata.data.counts_by_hourly.map(item => );
            if (tbldata.data.counts_by_hourly.length > 0) {
                const formattedData = tbldata.data.counts_by_hourly.map((value, index) => ({
                    x: (parseInt(value.hour_number) + 1),
                    y: getTotalCount(value),
                    avg: secondsToHHMMSSsum(value),
                    calls: getTotalCount(value), // Example value, replace with your actual data
                    time: 'At ' + convertToAMPM((parseInt(value.hour_number) + 1)),
                    name: 'Total calls'
                }));
                setbusyhour([
                    {
                        name: 'Busy Hours',
                        data: formattedData
                    }
                ]);
                setoptionbusyhour(
                    {
                        chart: {
                            height: 350,
                            type: 'bar',
                        },
                        plotOptions: {
                            bar: {
                                borderRadius: 10,
                                columnWidth: '50%',
                            }
                        },
                        dataLabels: {
                            enabled: false
                        },
                        stroke: {
                            width: 2
                        },

                        grid: {
                            row: {
                                colors: ['#fff', '#f2f2f2']
                            }
                        },
                        colors: ['#288596'],
                        tooltip: {
                            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                var data1 = w.globals.initialSeries[0].data[dataPointIndex];

                                return (
                                    '<div class="card border-light chart-tooltip"> <div class="card-header theader">' + data1.time + '</div> <div class="card-body tbody "> <h5 class="card-title ttitle">' + data1.name + '</h5> <p class="tp">' + data1.calls + '</p> </div> </div>'
                                );


                                return (
                                    ' '
                                );
                            }
                        }
                    });
            }
        }
        if (tbldata && tbldata.data && tbldata.data.counts_by_hourly && tbldata.data.counts_by_hourly.length > 0) {
            // let xAxisValue = tbldata.data.counts_by_hourly.map(item => );
            if (tbldata.data.counts_by_hourly.length > 0) {
                const formattedData = tbldata.data.counts_by_hourly.map((value, index) => ({
                    x: (parseInt(value.hour_number) + 1),
                    y: sumtime(value),
                    avg: secondsToHHMMSSsum(value),
                    calls: getTotalCount(value), // Example value, replace with your actual data
                    time: 'At ' + convertToAMPM((parseInt(value.hour_number) + 1)),
                    name: 'Avg Time Taken'
                }));
                setxnurse([
                    {
                        name: 'Avg Time Taken',
                        data: formattedData
                    }
                ]);
                setoptionxnurse(
                    {
                        chart: {
                            type: "area",
                            height: 350,
                            foreColor: "#999",
                            stacked: true,
                            dropShadow: {
                                enabled: true,
                                enabledSeries: [0],
                                top: -2,
                                left: 2,
                                blur: 5,
                                opacity: 0.06
                            }
                        },
                        plotOptions: {
                            bar: {
                                borderRadius: 10,
                                columnWidth: '50%',
                            }
                        },
                        dataLabels: {
                            enabled: false
                        },
                        stroke: {
                            width: 2
                        },

                        grid: {
                            row: {
                                colors: ['#fff', '#f2f2f2']
                            }
                        },
                        fill: {
                            opacity: 1,
                            type: 'pattern',
                            pattern: {
                                style: ['verticalLines', 'horizontalLines'],
                                width: 5,
                                height: 6
                            },
                        },

                        colors: ['#f3931b'],
                        tooltip: {
                            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                var data1 = w.globals.initialSeries[0].data[dataPointIndex];

                                return (
                                    '<div class="card border-light chart-tooltip"> <div class="card-header theader">' + data1.time + '</div> <div class="card-body tbody "> <h5 class="card-title ttitle">' + data1.name + '</h5> <p class="tp">' + data1.avg + '<span class="tpspan "> ' + data1.calls + ' Calls</span></p> </div> </div>'
                                );


                                return (
                                    ' '
                                );
                            }
                        }
                    });
            }
        }
    }, [tbldata]); // Execute this effect whenever tbldata changes
    //#region ====================================================== shift Dropdown Bind ======================================================
    // useEffect(() => {
    //     if (state.show_filter && tblshift.length == 0) {
    //         const fetchData = async () => {
    //             const { data: shiftdata, errorshift, isValidatingshift } = await getAPI(apiShift, false);
    //             setTblShift(shiftdata);
    //             //  tblshift = shiftdata;
    //         };
    //         fetchData();
    //     }
    //     if (state.show_filter && tblward.length == 0) {
    //         const fetchData = async () => {
    //             const { data: warddata, errorward, isValidatingward } = await getAPI(apiWard, false);
    //             setTblward(warddata);
    //             //  tblshift = shiftdata;
    //         };
    //         fetchData();
    //     }
    //     if (state.show_filter && tbldisplay.length == 0) {
    //         const fetchData = async () => {
    //             const { data: displaydata, errordisplay, isValidatingdisplay } = await getAPI(apiDisplay, false);
    //             setTbldisplay(displaydata);
    //             //  tblshift = shiftdata;
    //         };
    //         fetchData();
    //     }
    //     if (state.show_filter && tblcall.length == 0) {
    //         const fetchData = async () => {
    //             const { data: calldata, errorcall, isValidatingcall } = await getAPI(apiCall, false);
    //             setTblcall(calldata);
    //             //  tblshift = shiftdata;
    //         };
    //         fetchData();
    //     }
    // }, [state.show_filter]);

    //#region ====================================================== shift Dropdown Bind ======================================================
    useEffect(() => {

        if (state.show_filter && state.call_api == true && tblshift.length == 0) {
            const fetchData = async () => {
                const { data: shiftdata, errorshift, isValidatingshift } = await getAPI(apiShift, false);
                setTblShift(shiftdata);
                //console.log(tblshift);
                //  tblshift = shiftdata;
            };
            fetchData();
        }
        if (state.show_filter && state.call_api == true && state.fields.hospital_id != "" && state.fields.hospital_id != "0") {
            const fetchData = async () => {
                const { data: warddata, errorward, isValidatingward } = await getAPI(apiWard + "&hospital_id=" + state.fields.hospital_id, false);
                setTblward(warddata);
                //  tblshift = shiftdata;
            };
            fetchData();
            setState((prevState) => ({
                ...prevState,
                call_api: false
            }));

        }
        if (state.show_filter && state.call_api == true && state.fields.hospital_id != "" && state.fields.hospital_id != "0") {
            const fetchData = async () => {
                const { data: displaydata, errordisplay, isValidatingdisplay } = await getAPI(apiDisplay + "&hospital_id=" + state.fields.hospital_id, false);
                setTbldisplay(displaydata);
                //  tblshift = shiftdata;
            };
            fetchData();
        }
        if (state.show_filter && state.call_api == true && tblcall.length == 0) {
            const fetchData = async () => {
                const { data: calldata, errorcall, isValidatingcall } = await getAPI(apiCall, false);
                setTblcall(calldata);
                //  tblshift = shiftdata;
            };
            fetchData();
        }
    }, [state.show_filter, state.call_api]);
    // const { data: shiftdata, errorshift, isValidatingshift } = useSWR(
    //     apiShift,
    //     () => getAPI(apiShift, false),
    //     {
    //         revalidateOnReconnect: false,
    //         revalidateOnFocus: false,
    //     }
    // );
    // tblshift = shiftdata;
    //#endregion


    // const { data: shiftdata, errorshift, isValidatingshift } = useSWR(
    //     apiShift,
    //     () => getAPI(apiShift, false),
    //     {
    //         revalidateOnReconnect: false,
    //         revalidateOnFocus: false,
    //     }
    // );
    // tblshift = shiftdata;
    //#endregion
    const formatDuration = (durationInSeconds) => {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = Math.round(durationInSeconds % 60);

        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    };
    const pad = (number) => {
        return number.toString().padStart(2, '0');
    };
    //#region ====================================================== Filter Section ======================================================
    const applyFilter = async () => {
        try {
            setState((prevState) => ({
                ...prevState,
                filter_applied: true,
                apifields: {
                    ...prevState.apifields,
                    hospital_id: prevState.fields.hospital_id,
                    start_date: prevState.fields.start_date,
                    end_date: prevState.fields.end_date,
                    shift_id: prevState.fields.shift_id,
                    display_id: prevState.fields.display_id,
                    ward_id: prevState.fields.ward_id,
                    call_id: prevState.fields.call_id,
                    is_escalated: prevState.fields.is_escalated
                },
            }));
            // //console.log(setState);
            setPage(1);
        } catch (err) { }
    };
    const clearFilter = async () => {
        try {
            const updatedapifieldsState = {
                hospital_id: state.fields.hospital_id,
                shift_id: "",
                display_id: "",
                ward_id: "",
                call_id: "",
                is_escalated: "",
                start_date: state.fields.start_date,
                end_date: state.fields.end_date
            };
            const updatedfieldState = {
                hospital_id: state.fields.hospital_id,
                shift_id: "",
                display_id: "",
                ward_id: "",
                call_id: "",
                is_escalated: "",
                start_date: state.fields.start_date,
                end_date: state.fields.end_date
            };
            updateState("apifields", updatedapifieldsState);
            updateState("fields", updatedfieldState);
            updateState("filter_applied", false);
        } catch (err) { }
    };
    const showFilter = async () => {
        try {
            updateState("show_filter", true);
        } catch (err) { }
    };
    const hideFilter = async () => {
        try {
            updateState("show_filter", false);
        } catch (err) { }
    };
    //#endregion
    //#region ====================================================== Multi Select2 Dropdown ======================================================
    const handleSelectMultipleChange = (event) => {
        const selectedOptions = Array.from(
            event.target.selectedOptions,
            (option) => option.value
        );
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                shift_id: selectedOptions,
            },
        }));
    };
    //#endregion
    //#region ====================================================== Multi Select2 Dropdown ======================================================
    const handleSelectMultipleChangedisplay = (event) => {
        const selectedOptions = Array.from(
            event.target.selectedOptions,
            (option) => option.value
        );
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                display_id: selectedOptions,
            },
        }));
    };
    //#endregion
    //#region ====================================================== Multi Select2 Dropdown ======================================================
    const handleSelectMultipleChangeward = (event) => {
        const selectedOptions = Array.from(
            event.target.selectedOptions,
            (option) => option.value
        );
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                ward_id: selectedOptions,
            },
        }));
    };
    //#endregion
    //#region ====================================================== Multi Select2 Dropdown ======================================================
    const handleSelectMultipleChangecall = (event) => {
        const selectedOptions = Array.from(
            event.target.selectedOptions,
            (option) => option.value
        );
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                call_id: selectedOptions,
            },
        }));
    };
    //#endregion


    //#region ====================================================== hospital Dropdown Bind ======================================================
    const { data: hospitaldata, errorhospital, isValidatinghospital } = useSWR(
        apiHospital,
        () => getAPI(apiHospital, false),
        {
            revalidateOnReconnect: false,
            revalidateOnFocus: false,
        }
    );
    tblhospitals = hospitaldata;
    //#endregion

    //#region ====================================================== Toast Message set ======================================================
    const showToast = (message) => {
        updateState("show_toast", true);
        updateState("toast_msg", message);
        setTimeout(() => {
            updateState("show_toast", false);
        }, 5000);
    };
    const hideToast = () => {
        updateState("show_toast", false);
    };
    //#endregion

    //#region ====================================================== Get API ======================================================
    const getAPI = async (url, Auth) => {
        try {
            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                if (Auth) {
                    url += `&user_id=${getUser.user_id}`;
                }
                const response = await axios.get(`${baseURL.serverUrl}${url}`, {
                    headers: {
                        "x-access-token": getUser.token,
                        Authorization: `Bearer ${getUser.token}`,
                    },
                });
                //console.log(response.data)
                return response.data;
            } else {
                return {};
            }
        } catch (err) {
            return {};
        }
    };
    //#endregion  
    function convertToAMPM(time) {
        var hours = parseInt(time);
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert to 12-hour format
        return hours + ' ' + ampm;
    }
    //#region ====================================================== Date validation ======================================================
    const handleDateChange = (event) => {
        const { name, value } = event.target;
        const { start_date, end_date } = state.fields;
        const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(value);
        if (!isValidDate) {
            showToast("Invalid date format");
            return;
        }
        if (name === "start_date" && new Date(value) > new Date(end_date)) {
            showToast("Start date cannot be greater than end date");
            return;
        }
        if (name === "end_date" && new Date(value) < new Date(start_date)) {
            showToast("End date cannot be less than start date");
            return;
        }
        // Update the state
        setState((prevState) => ({
            ...prevState,
            fields: { ...prevState.fields, [name]: value },
        }));
    };
    //#endregion
    //#region ====================================================== Checkbox Handler ======================================================
    const handleCheckboxClick = () => {
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                is_escalated: !prevState.fields.is_escalated,
            },
        }), () => {
            //console.log(state);
        });
    };
    //#endregion
    // Convert seconds to "hh:mm:ss" format
    function secondsToHHMMSSsum(secondstotal) {

        const codeBlueAvg = parseInt(secondstotal.codeblueavg || 0);
        const extraHelpAvg = parseInt(secondstotal.extrahelpavg || 0);
        const houseCallAvg = parseInt(secondstotal.housecallavg || 0);
        const nurseCallAvg = parseInt(secondstotal.nursecallavg || 0);
        const toiletCallAvg = parseInt(secondstotal.toiletcallavg || 0);

        // Calculate total call average for non-zero averages
        const totalCallAvgValues = [codeBlueAvg, extraHelpAvg, houseCallAvg, nurseCallAvg, toiletCallAvg].filter(avg => avg > 0);
        const totalCallAvg = totalCallAvgValues.length > 0 ? totalCallAvgValues.reduce((acc, curr) => acc + curr) / totalCallAvgValues.length : 0;
        let seconds = totalCallAvg;

        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    }
    function sumtime(secondstotal) {

        const codeBlueAvg = parseInt(secondstotal.codeblueavg || 0);
        const extraHelpAvg = parseInt(secondstotal.extrahelpavg || 0);
        const houseCallAvg = parseInt(secondstotal.housecallavg || 0);
        const nurseCallAvg = parseInt(secondstotal.nursecallavg || 0);
        const toiletCallAvg = parseInt(secondstotal.toiletcallavg || 0);

        // Calculate total call average for non-zero averages
        const totalCallAvgValues = [codeBlueAvg, extraHelpAvg, houseCallAvg, nurseCallAvg, toiletCallAvg].filter(avg => avg > 0);
        const totalCallAvg = totalCallAvgValues.length > 0 ? totalCallAvgValues.reduce((acc, curr) => acc + curr) / totalCallAvgValues.length : 0;
        let seconds = totalCallAvg;
        return seconds;
    }
    function secondsToHHMMSS(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    }

    // New CODE
    const getTotalCount = (counts) => {
        const value = parseInt(counts.codebluecount || 0) +
            parseInt(counts.toiletcallcount || 0) +
            parseInt(counts.extrahelpcount || 0) +
            parseInt(counts.nursecallcount || 0) +
            parseInt(counts.housecallcount || 0);

        return value;
    };
    const calculatePercentage = (value1, value2) => {
        let calPer = '';
        if (value1 - value2 > 0) {
            if (value2 === 0) {
            } else {
                calPer = ((value1 - value2) * 100 / value2).toString();
            }
        } else {
            if (value1 === 0) {
            } else {
                calPer = ((value1 - value2) * 100 / value1).toString();
            }
        }
        return calPer;
    };
    useEffect(() => {
        if (
            tblhospitals &&
            tblhospitals.data &&
            tblhospitals.data.hasOwnProperty("hospitals") &&
            tblhospitals.data.hospitals.length == 1
        ) {
            setState((prevState) => ({
                ...prevState,
                fields: {
                    ...prevState.fields,
                    hospital_id: tblhospitals.data.hospitals[0].hospital_id,
                },
            }));
        }
    }, [tblhospitals]);














    useEffect(() => {
        if (state.send_alert === true) {
            const saveData = async () => {
                try {
                    let bodydata = {
                        ID: 1,
                        Dev: 12
                    };
                    const result = await postAPI('siscall/codeblue', bodydata, true);
                    if (result && result.statusCode === 200 && result.success === true) {
                        showToast("Code blue alert sent successfully");
                    }
                    //  else {
                    //     showToast("Something went wrong. Please try again later.");
                    // }

                } catch (err) {
                }
            };
            saveData();
            updateState("saved", false);

        }
    }, [state.send_alert]);
    const saveClicked = () => {

        setState((prevState) => ({
            ...prevState,
            send_alert: false
        }));
        setState((prevState) => ({
            ...prevState,
            send_alert: true
        }));


        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const debouncedSaveClicked = debounce(saveClicked, 500);

    //#region ====================================================== Post API ======================================================
    const postAPI = async (url, data, Auth) => {
        try {
            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                if (Auth) {
                    // Pass user_id through data
                    data.user_id = getUser.user_id;
                }
                //console.log("inside Post data");
                //console.log(data);
                const response = await axios.post(`${baseURL.serverUrl}${url}`, data, {
                    headers: {
                        "x-access-token": getUser.token,
                        Authorization: `Bearer ${getUser.token}`,
                        'Content-Type': 'application/json',
                    },
                });
                return response.data;
            } else {
                return {};
            }
        } catch (err) {
            return {};
        }
    };
    //#endregion 


    return (
        <>
            {tblhasrights &&
                tblhasrights.data &&
                Array.isArray(tblhasrights.data.hasrights) &&
                tblhasrights.data.hasrights.length > 0 &&
                tblhasrights.data.hasrights[0].hasOwnProperty("view_status") &&
                tblhasrights.data.hasrights[0]["view_status"] && (
                    <>
                        <div className="row g-gs">
                            <div className="col-xxl-12">
                                <div className="card h-100">
                                    <div className="card-body boxpadding">
                                        <div className="card-title-group mb-4 align-items-start">
                                            <div className="card-title">
                                                <h4 className="title mb-0 lineheight0">General Overview</h4>
                                                <span className="small subheader">Get an at-a-glance view of key information and statistics for a comprehensive overview of the hospital's calls and average duration.</span>
                                            </div>
                                            <div className="card-tools">
                                                {!state.show_filter && (
                                                    <>
                                                        <button
                                                            type="button"
                                                            className="btn btn-color-dark d-none d-md-inline-flex  mr10"
                                                            name="btnshowfilter"
                                                            onClick={(e) => showFilter()}
                                                        >
                                                            <em className="icon ni ni-filter"></em>
                                                            <span>Show Filter</span>
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row g-gs">
                                            {showHospital ? (<div className="col-lg-3">
                                                <div className="form-group">

                                                    <Select2
                                                        data={[
                                                            ...(tblhospitals && tblhospitals.data && tblhospitals.data.hasOwnProperty("hospitals") && tblhospitals.data.hospitals || []).map((x) => ({
                                                                text: x.hospital_name,
                                                                id: x.hospital_id,
                                                                key: x.hospital_id.toString(),
                                                            })),
                                                        ]}
                                                        options={{
                                                            placeholder: "Select hospital",
                                                        }}
                                                        name="hospital_id"
                                                        className="form-control"
                                                        value={state.fields.hospital_id}
                                                        onChange={handleInputChangeHospital}
                                                    />

                                                </div>
                                            </div>) : <></>
                                            }
                                            {/* <div className="col-lg-3">
                                                <div className="form-group">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary d-none d-md-inline-flex mr10"
                                                        name="btnfilter"
                                                        onClick={debouncedSaveClicked}
                                                    > 
                                                        <span>Code Blue Alert</span>
                                                    </button>
                                                </div>
                                            </div> */}
                                            <div className="col-lg-6 titlealign">
                                                {tbldata && tbldata.data && tbldata.data ? (
                                                    <>
                                                        <div class="small titlesubcolor">
                                                            {/* <div>
                                                                        {tbldata.data.devicetitle}
                                                                    </div> */}
                                                            <div>
                                                                {tbldata.data.devicedescription}
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className="norecordcss1">
                                                    </div>
                                                )}
                                            </div>


                                        </div>
                                        <div className="row g-gs margintop0">


                                            {/* ====================================================== Filter section ====================================================== */}
                                            {state.show_filter && (
                                                <>
                                                    <div>
                                                        <span className="dividerlist  mt-0"></span>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <h5 className="summary">
                                                                    Filter
                                                                    <span className="float-end">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-color-danger d-none d-md-inline-flex"
                                                                            name="btnhidefilter"
                                                                            onClick={(e) => hideFilter()}
                                                                        >
                                                                            <em className="icon ni ni-cross"></em>
                                                                            <span>Hide filter</span>
                                                                        </button>
                                                                    </span>
                                                                </h5>
                                                                <p className="ptitle">
                                                                    Refine your search effortlessly with our advanced
                                                                    filtering options.
                                                                </p>
                                                            </div>
                                                            <div className="col-md-12 mt-0">
                                                                <div className="row">
                                                                    <div className="col-lg-3">
                                                                        <div className="form-group">
                                                                            <label className="form-label">
                                                                                Shift
                                                                            </label>
                                                                            <Select2
                                                                                multiple
                                                                                data={[
                                                                                    ...(tblshift && tblshift.hasOwnProperty("shift") && tblshift.shift || []).map((x) => ({
                                                                                        text: x.shift_name,
                                                                                        id: x.shift_id,
                                                                                        key: x.shift_id.toString(),
                                                                                    })),
                                                                                ]}
                                                                                options={{
                                                                                    placeholder: "select multiple option",
                                                                                }}
                                                                                name="shifts"
                                                                                className="form-control"
                                                                                value={state.fields.shift_id}
                                                                                onChange={handleSelectMultipleChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3">
                                                                        <div className="form-group">
                                                                            <label className="form-label">
                                                                                Nurse station
                                                                            </label>
                                                                            <Select2
                                                                                multiple
                                                                                data={[
                                                                                    ...(tbldisplay && tbldisplay && tbldisplay.hasOwnProperty("displaylist") && tbldisplay.displaylist || []).map((x) => ({
                                                                                        text: x.display_name,
                                                                                        id: x.display_id,
                                                                                        key: x.display_id.toString(),
                                                                                    })),
                                                                                ]}
                                                                                options={{
                                                                                    placeholder: "select multiple option",
                                                                                }}
                                                                                name="displays"
                                                                                className="form-control"
                                                                                value={state.fields.display_id}
                                                                                onChange={handleSelectMultipleChangedisplay}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-3">
                                                                        <div className="form-group">
                                                                            <label className="form-label">
                                                                                Ward
                                                                            </label>
                                                                            <Select2
                                                                                multiple
                                                                                data={[
                                                                                    ...(tblward && tblward.hasOwnProperty("wardlist") && tblward.wardlist || []).map((x) => ({
                                                                                        text: x.ward_name,
                                                                                        id: x.ward_id,
                                                                                        key: x.ward_id.toString(),
                                                                                    })),
                                                                                ]}
                                                                                options={{
                                                                                    placeholder: "select multiple option",
                                                                                }}
                                                                                name="wards"
                                                                                className="form-control"
                                                                                value={state.fields.ward_id}
                                                                                onChange={handleSelectMultipleChangeward}
                                                                            />
                                                                        </div>
                                                                    </div>


                                                                    <div className="col-lg-3">
                                                                        <div className="form-group">
                                                                            <label className="form-label">
                                                                                Call type
                                                                            </label>
                                                                            <Select2
                                                                                multiple
                                                                                data={[
                                                                                    ...(tblcall && tblcall.hasOwnProperty("call") && tblcall.call || []).map((x) => ({
                                                                                        text: x.call_name,
                                                                                        id: x.call_id,
                                                                                        key: x.call_id.toString(),
                                                                                    })),
                                                                                ]}
                                                                                options={{
                                                                                    placeholder: "select multiple option",
                                                                                }}
                                                                                name="calls"
                                                                                className="form-control"
                                                                                value={state.fields.call_id}
                                                                                onChange={handleSelectMultipleChangecall}
                                                                            />
                                                                        </div>
                                                                    </div>


                                                                    <div className="col-md-3">
                                                                        <div className="form-group">
                                                                            <label className="form-label">
                                                                                Escalated Only
                                                                            </label>
                                                                            <div className="form-control-wrap">
                                                                                <div className="form-check form-switch">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="checkbox"
                                                                                        checked={state.fields.is_escalated}
                                                                                        onChange={handleCheckboxClick}
                                                                                        value=""
                                                                                    />


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <div className="form-group">
                                                                            <label className="form-label">
                                                                                Start date
                                                                            </label>
                                                                            <div className="form-control-wrap">
                                                                                <input
                                                                                    type="date"
                                                                                    name="start_date"
                                                                                    className="form-control"
                                                                                    value={state.fields.start_date}
                                                                                    onChange={handleDateChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <div className="form-group">
                                                                            <label className="form-label">
                                                                                End date
                                                                            </label>
                                                                            <div className="form-control-wrap">
                                                                                <input
                                                                                    type="date"
                                                                                    name="end_date"
                                                                                    className="form-control"
                                                                                    value={state.fields.end_date}
                                                                                    onChange={handleDateChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-primary d-none d-md-inline-flex mr10"
                                                                                name="btnfilter"
                                                                                onClick={(e) => applyFilter()}
                                                                            >
                                                                                <em className="icon ni ni-search"></em>
                                                                                <span>Filter</span>
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-color-info d-none d-md-inline-flex"
                                                                                name="btnclearfilter"
                                                                                onClick={(e) => clearFilter()}
                                                                            >
                                                                                <em className="icon ni ni-delete"></em>
                                                                                <span>Clear filter</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <span className="dividerlist mt-4 mb-4"></span>
                                                    </div>
                                                </>
                                            )}
                                            {tbldata && tbldata.data && tbldata.data.call_counts && tbldata.data.call_counts.length > 0 ? (
                                                <>
                                                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-2">
                                                        <div className="box-dotted h-100">
                                                            <div className="imgback bgcodeblue">
                                                                <img src={code_blue} className="whiteimage" />
                                                            </div>
                                                            <h5 className="color mb-0">Code blue</h5>
                                                            <div className="amount pb-0 mb-0 h3">
                                                                <span className="dashboard-title1">{formatDuration(tbldata.data.call_counts[0].codeblueavg)}</span>
                                                                {/* <div className="dashboard-title small">
                                                                    {tbldata && tbldata.data && tbldata.data.prev_call_counts && tbldata.data.prev_call_counts.length > 0 && (
                                                                        <>
                                                                            <div className="change up">
                                                                                <em className="icon ni ni-trend-up"></em> +{calculatePercentage(tbldata.data.call_counts[0].codebluecount, tbldata.data.prev_call_counts[0].codebluecount)}%
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div> */}
                                                            </div>
                                                            <h5 className="title color">{tbldata.data.call_counts[0].codebluecount} calls</h5>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-2">
                                                        <div className="box-dotted h-100">
                                                            <div className="imgback bgnurse">
                                                                <img src={nurse_icon} className="whiteimage" />
                                                            </div>
                                                            <h5 className="mb-0 color">Nurse call</h5>
                                                            <div className="amount pb-0 mb-0 h3">
                                                                <span className="dashboard-title1">{formatDuration(tbldata.data.call_counts[0].nursecallavg)}</span>
                                                                {/* <div className="dashboard-title small">
                                                                    {tbldata && tbldata.data && tbldata.data.prev_call_counts && tbldata.data.prev_call_counts.length > 0 && (
                                                                        <>
                                                                            <div className="change up">
                                                                                <em className="icon ni ni-trend-up"></em> +{calculatePercentage(tbldata.data.call_counts[0].nursecallcount, tbldata.data.prev_call_counts[0].nursecallcount)}%
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div> */}
                                                            </div>
                                                            <h5 className="title color">{tbldata.data.call_counts[0].nursecallcount} calls</h5>

                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-2">
                                                        <div className="box-dotted h-100">
                                                            <div className="imgback bgextra">
                                                                <img src={help_icon} className="whiteimage" />
                                                            </div>

                                                            <h5 className="mb-0 color">Extra help</h5>
                                                            <div className="amount pb-0 mb-0 h3">
                                                                <span className="dashboard-title1">{formatDuration(tbldata.data.call_counts[0].extrahelpavg)}</span>
                                                                {/* <div className="dashboard-title small">
                                                                    {tbldata && tbldata.data && tbldata.data.prev_call_counts && tbldata.data.prev_call_counts.length > 0 && (
                                                                        <>
                                                                            <div className="change up">
                                                                                <em className="icon ni ni-trend-up"></em> +{calculatePercentage(tbldata.data.call_counts[0].extrahelpcount, tbldata.data.prev_call_counts[0].extrahelpcount)}%
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div> */}
                                                            </div>
                                                            <h5 className="title color">{tbldata.data.call_counts[0].extrahelpcount} calls</h5>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-2">
                                                        <div className="box-dotted h-100">

                                                            <div className="imgback bghouse">
                                                                <img src={house_icon} className="whiteimage" />
                                                            </div>
                                                            <h5 className="mb-0 color">House call</h5>
                                                            <div className="amount pb-0 mb-0 h3">
                                                                <span className="dashboard-title1">{formatDuration(tbldata.data.call_counts[0].housecallavg)}</span>
                                                                {/* <div className="dashboard-title small">
                                                                    {tbldata && tbldata.data && tbldata.data.prev_call_counts && tbldata.data.prev_call_counts.length > 0 && (
                                                                        <>
                                                                            <div className="change up">
                                                                                <em className="icon ni ni-trend-up"></em> +{calculatePercentage(tbldata.data.call_counts[0].housecallcount, tbldata.data.prev_call_counts[0].housecallcount)}%
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div> */}
                                                            </div>
                                                            <h5 className="title color">{tbldata.data.call_counts[0].housecallcount} calls</h5>

                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-2">
                                                        <div className="box-dotted h-100">
                                                            <div className="imgback bgtoilet">
                                                                <img src={toilet_icon} className="whiteimage" />
                                                            </div>
                                                            <h5 className="mb-0 color">Toilet call</h5>
                                                            <div className="amount pb-0 mb-0 h3">
                                                                <span className="dashboard-title1">{formatDuration(tbldata.data.call_counts[0].toiletcallavg)}</span>
                                                                {/* <div className="dashboard-title small">
                                                                    {tbldata && tbldata.data && tbldata.data.prev_call_counts && tbldata.data.prev_call_counts.length > 0 && (
                                                                        <>
                                                                            <div className="change up">
                                                                                <em className="icon ni ni-trend-up"></em> +{calculatePercentage(tbldata.data.call_counts[0].toiletcallcount, tbldata.data.prev_call_counts[0].toiletcallcount)}%
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div> */}
                                                            </div>
                                                            <h5 className="title color">{tbldata.data.call_counts[0].toiletcallcount} calls</h5>

                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="norecordcss1">
                                                    <img src={emptyrecord} width="330px" alt="No record found" />
                                                    <div className="norecordfont">No record found</div>
                                                </div>
                                            )}


                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xxl-6">
                                <div className="card h-500">
                                    <div className="card-body boxpadding">
                                        <div className="card-title-group">
                                            <div className="card-title">
                                                <h4 className="title mb-0 lineheight0">High Frequency Calls</h4>
                                                <span className="small subheader">
                                                    Identify and analyze calls with the highest frequency data to improve response times and patient care efficiency.</span>
                                            </div>
                                        </div>

                                        <div className="nk-chart-marketing-campaign-overview mb-2">
                                            {tbldata && tbldata.data && tbldata.data.counts_by_bt_max && tbldata.data.counts_by_bt_max.length > 0 ? (
                                                <>
                                                    {optionhighfreq && Object.keys(optionhighfreq).length > 0 &&
                                                        (<Chart
                                                            options={optionhighfreq}
                                                            series={highfreq}
                                                            height="380"
                                                            type="bar"
                                                        />
                                                        )}
                                                </>
                                            ) : (
                                                <div className="norecordcss1">
                                                    <img src={emptyrecord} width="330px" alt="No record found" />
                                                    <div className="norecordfont">No record found</div>
                                                </div>
                                            )}



                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6">
                                <div className="card h-500">
                                    <div className="card-body boxpadding">
                                        <div className="card-title-group">
                                            <div className="card-title">
                                                <h4 className="title mb-0 lineheight0">Busy Hours</h4>
                                                <span className="small subheader">Analyze the hospital's peak activity hours and call volume patterns for optimized scheduling and resource allocation.</span>
                                            </div>
                                        </div>
                                        <div className="nk-chart-marketing-campaign-overview mb-2">
                                            {tbldata && tbldata.data && tbldata.data.counts_by_hourly && tbldata.data.counts_by_hourly.length > 0 ? (
                                                <>
                                                    {optionbusyhour && Object.keys(optionbusyhour).length > 0 &&
                                                        (<Chart
                                                            options={optionbusyhour}
                                                            series={busyhour}
                                                            height="380" type="bar"
                                                        />
                                                        )}
                                                </>
                                            ) : (
                                                <div className="norecordcss1">
                                                    <img src={emptyrecord} width="330px" alt="No record found" />
                                                    <div className="norecordfont">No record found</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-12">
                                <div className="card h-500">
                                    <div className="card-body boxpadding">
                                        <div className="card-title-group">
                                            <div className="card-title">
                                                <h4 className="title mb-0 lineheight0">Nurse Availability Insights</h4>
                                                <span className="small subheader">Gain valuable insights into nurse availability patterns to ensure efficient staffing and timely patient care.</span>
                                            </div>
                                        </div>

                                        <div className="nk-chart-marketing-campaign-overview mb-2">
                                            {tbldata && tbldata.data && tbldata.data.counts_by_hourly && tbldata.data.counts_by_hourly.length > 0 ? (
                                                <>
                                                    {optionxnurse && Object.keys(optionxnurse).length > 0 &&
                                                        (<Chart
                                                            options={optionxnurse}
                                                            series={xnurse}
                                                            height="380"
                                                            type="area"
                                                        />
                                                        )}
                                                </>
                                            ) : (
                                                <div className="norecordcss1">
                                                    <img src={emptyrecord} width="330px" alt="No record found" />
                                                    <div className="norecordfont">No record found</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-12">
                                <div className="card h-100">
                                    <div className="card-body flex-grow-0 paddingbottom0">
                                        <div className="card-title-group  align-items-start marginbottom5">
                                            <div className="card-title">
                                                <h4 className="title mb-0 lineheight0">Shifts</h4>
                                                <span className="small subheader">Examine call data categorized by different shifts to gain insights into call patterns and response efficiency during each shift.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-content" id="myTabContent">
                                        {tbldata && tbldata.data && tbldata.data.counts_by_shift && tbldata.data.counts_by_shift.length > 0 ? (
                                            <>
                                                <div className="sbox1">
                                                    <div className="sbox">
                                                        {tbldata &&
                                                            tbldata.data.counts_by_shift.map((x) => {
                                                                // Parse counts to integers and handle null values
                                                                const codeBlueCount = parseInt(x.codebluecount || 0);
                                                                const extraHelpCount = parseInt(x.extrahelpcount || 0);
                                                                const houseCallCount = parseInt(x.housecallcount || 0);
                                                                const nurseCallCount = parseInt(x.nursecallcount || 0);
                                                                const toiletCallCount = parseInt(x.toiletcallcount || 0);

                                                                // Calculate total call count
                                                                const totalCallCount = codeBlueCount + extraHelpCount + houseCallCount + nurseCallCount + toiletCallCount;

                                                                // Parse averages to floats and handle null values
                                                                const codeBlueAvg = parseInt(x.codeblueavg || 0);
                                                                const extraHelpAvg = parseInt(x.extrahelpavg || 0);
                                                                const houseCallAvg = parseInt(x.housecallavg || 0);
                                                                const nurseCallAvg = parseInt(x.nursecallavg || 0);
                                                                const toiletCallAvg = parseInt(x.toiletcallavg || 0);

                                                                // Calculate total call average for non-zero averages
                                                                const totalCallAvgValues = [codeBlueAvg, extraHelpAvg, houseCallAvg, nurseCallAvg, toiletCallAvg].filter(avg => avg > 0);
                                                                const totalCallAvg = totalCallAvgValues.length > 0 ? totalCallAvgValues.reduce((acc, curr) => acc + curr) / totalCallAvgValues.length : 0;

                                                                return (
                                                                    <div className="sboxcard" key={x.shift_id}>
                                                                        <div className="sboxback">
                                                                            <span className="sbox-title"> {x.shift_name}</span>
                                                                            <span className="sbox-right">
                                                                                <em className="icon icon-lg ni ni-chevron-right-circle sbox-right-icon"></em>
                                                                            </span>
                                                                        </div>
                                                                        <div className="sbox-desc">
                                                                            <span className="sbox-desc-title">Call Volume</span>
                                                                            <div className="sbox-desc-right">
                                                                                {totalCallCount}
                                                                            </div>
                                                                        </div>
                                                                        <div className="sbox-desc">
                                                                            <span className="sbox-desc-title">Call Average</span>
                                                                            <div className="sbox-desc-right">
                                                                                {formatDuration(totalCallAvg.toFixed(2))}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                );
                                                            })
                                                        }

                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="norecordcss1">
                                                <img src={emptyrecord} width="330px" alt="No record found" />
                                                <div className="norecordfont">No record found</div>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-12">
                                <div className="card h-100">
                                    <div className="card-body flex-grow-0 paddingbottom0">
                                        <div className="card-title-group  align-items-start marginbottom5">
                                            <div className="card-title">
                                                <h4 className="title mb-0 lineheight0">Display</h4>
                                                <span className="small subheader">Analyze data segmented by different displays to understand call distribution and performance for each display unit.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-content" id="myTabContent">

                                        {tbldata && tbldata.data && tbldata.data.counts_by_display && tbldata.data.counts_by_display.length > 0 ? (
                                            <>
                                                <div className="sbox1">
                                                    <div className="sbox">
                                                        {tbldata &&
                                                            tbldata.data.counts_by_display.map((x) => {
                                                                // Parse counts to integers and handle null values
                                                                const codeBlueCount = parseInt(x.codebluecount || 0);
                                                                const extraHelpCount = parseInt(x.extrahelpcount || 0);
                                                                const houseCallCount = parseInt(x.housecallcount || 0);
                                                                const nurseCallCount = parseInt(x.nursecallcount || 0);
                                                                const toiletCallCount = parseInt(x.toiletcallcount || 0);

                                                                // Calculate total call count
                                                                const totalCallCount = codeBlueCount + extraHelpCount + houseCallCount + nurseCallCount + toiletCallCount;

                                                                // Parse averages to floats and handle null values
                                                                const codeBlueAvg = parseInt(x.codeblueavg || 0);
                                                                const extraHelpAvg = parseInt(x.extrahelpavg || 0);
                                                                const houseCallAvg = parseInt(x.housecallavg || 0);
                                                                const nurseCallAvg = parseInt(x.nursecallavg || 0);
                                                                const toiletCallAvg = parseInt(x.toiletcallavg || 0);

                                                                // Calculate total call average for non-zero averages
                                                                const totalCallAvgValues = [codeBlueAvg, extraHelpAvg, houseCallAvg, nurseCallAvg, toiletCallAvg].filter(avg => avg > 0);
                                                                const totalCallAvg = totalCallAvgValues.length > 0 ? totalCallAvgValues.reduce((acc, curr) => acc + curr) / totalCallAvgValues.length : 0;

                                                                return (
                                                                    <div className="sboxcard" key={x.display_id}>
                                                                        <div className="sboxback">
                                                                            <span className="sbox-title"> {x.display_name}</span>
                                                                            <span className="sbox-right">
                                                                                <em className="icon icon-lg ni ni-chevron-right-circle sbox-right-icon"></em>
                                                                            </span>
                                                                        </div>
                                                                        <div className="sbox-desc">
                                                                            <span className="sbox-desc-title">Call Volume</span>
                                                                            <div className="sbox-desc-right">
                                                                                {totalCallCount}
                                                                            </div>
                                                                        </div>
                                                                        <div className="sbox-desc">
                                                                            <span className="sbox-desc-title">Call Average</span>
                                                                            <div className="sbox-desc-right">
                                                                                {formatDuration(totalCallAvg.toFixed(2))}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                );
                                                            })
                                                        }

                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="norecordcss1">
                                                <img src={emptyrecord} width="330px" alt="No record found" />
                                                <div className="norecordfont">No record found</div>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            {/* ====================================================== Toast message ====================================================== */}
            {state.show_toast && (
                <div
                    className="toast align-items-center text-bg-primary border-0 fade show toastmsg"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                >
                    <div className="d-flex">
                        <div className="toast-body">{state.toast_msg}</div>
                        <button
                            type="button"
                            className="btn-close btn-close-white me-2 m-auto"
                            data-bs-dismiss="toast"
                            onClick={(e) => hideToast()}
                            aria-label="Close"
                        ></button>
                    </div>
                </div>
            )}
        </>
    );

};


export default adminLayout(Dashboard);