import 'font-awesome/css/font-awesome.min.css';
import './assets/css/style20d4.css?v1.1.2';
// import './assets/css/bootstrap.min.css';  
// import './assets/css/select2.css'; 
// import './assets/js/bundle.js'; 
// import './assets/js/scripts.js'; 
import './assets/js/jquery.min.js';
import './assets/js/bootstrap.min.js';
// import './assets/js/select2.min.js';   
import HospitalList from './pages/hospitalList.js'
import Index from './pages/index.js'
import Dashboard from './pages/dashboard'
import ResetPassword from './pages/auth/ResetPassword';
import LiveDashboardPage from './pages/liveDashboard';
import LiveDashboardPageNew from './pages/liveDashboardNew.js';
import ProfilePage from './pages/profile/ProfilePage';
import ChangePasswordPage from './pages/profile/ChangePasswordPage';
import UserPreferencesPage from './pages/profile/UserPreferencesPage';
import UserPage from './pages/userList';
import BirdviewPage from './pages/birdView';
import DesignationPage from './pages/designationList';
import AnnouncementPage from './pages/announcementList';
import HospitalSubscriptionList from './pages/hospitalSubscriptionList.js';
import SubscriptionPage from './pages/subscriptionList.js';
import HomeScreen from './pages/default.js';
import UserEntry from './pages/userEntry.js';
import DesignationEntry from './pages/designationEntry.js';
import AnnouncementEntry from './pages/announcementEntry.js';
import SubscriptionEntry from './pages/subscriptionEntry.js';
import HospitalEntry from './pages/hospitalEntry.js';
import CallLog from './pages/callLog.js';
import DisplayIpPage from './pages/nurseStation.js';
import HospitalSubscriptionEntry from './pages/hospitalSubscriptionEntry.js';

import WardEntry from './pages/wardEntry.js';
import WardPage from './pages/wardList';

import BedEntry from './pages/bedEntry.js';
import BedPage from './pages/bedList.js';

import DisplayEntry from './pages/displayEntry.js';
import DisplayPage from './pages/displayList.js';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
function App() {
    return (
        <Router>
            <Routes>
                {/* <Route exact path='/login' element={<Login />} /> */}
                <Route exact path='/' element={<Index />} />
                <Route exact path='/nursestations' element={<DisplayIpPage />} />
                <Route exact path='/hospitallist' element={<HospitalList />} />
                {/* <Route exact path='/dashboard' element={<Dashboard />} /> */}
                <Route path='/dashboard/*' element={<Dashboard />} />
                <Route exact path='/reset-password' element={<ResetPassword />} />
                <Route exact path='/profile' element={<ProfilePage />} />
                <Route exact path='/birdview' element={<BirdviewPage />} />
                <Route exact path='/change-password' element={<ChangePasswordPage />} />
                <Route exact path='/preferences' element={<UserPreferencesPage />} />
                <Route exact path='/userlist' element={<UserPage />} />
                <Route exact path='/designationlist' element={<DesignationPage />} />
                <Route exact path='/announcementlist' element={<AnnouncementPage />} />
                <Route exact path='/subscriptionList' element={<SubscriptionPage />} />
                <Route exact path='/livedashboard' element={<LiveDashboardPage />} />
                <Route exact path='/livedashboardnew' element={<LiveDashboardPageNew />} />
                <Route exact path='/userentry' element={<UserEntry />} />
                {/* <Route exact path='/Home' element={<HomePage />} /> */}
                <Route exact path='/home' element={<HomeScreen />} />
                <Route exact path='/hospitalsubscriptionlist' element={<HospitalSubscriptionList />} />
                <Route exact path='/designationentry' element={<DesignationEntry />} />
                <Route exact path='/announcemententry' element={<AnnouncementEntry />} />
                <Route exact path='/subscriptionentry' element={<SubscriptionEntry />} />
                <Route exact path='/hospitalentry' element={<HospitalEntry />} />
                <Route exact path='/calllog' element={<CallLog />} />
                <Route exact path='/hospitalsubscriptionentry' element={<HospitalSubscriptionEntry />} />
                <Route exact path='/wardlist' element={<WardPage />} />
                <Route exact path='/wardentry' element={<WardEntry />} />
                <Route exact path='/bedlist' element={<BedPage />} />
                <Route exact path='/bedentry' element={<BedEntry />} />
                <Route exact path='/nursestationlist' element={<DisplayPage />} />
                <Route exact path='/nursestationentry' element={<DisplayEntry />} />

            </Routes>
        </Router>
    )
}

export default App;
