
import React, { useState, useEffect, useCallback } from "react";
// import 'react-perfect-scrollbar/dist/css/styles.css';
import logoimage from "../assets/images/siscall.png";
import PerfectScrollbar from 'react-perfect-scrollbar';
import userimage from "../images/userimg.jpg";
import { Link } from 'react-router-dom';
import debounce from "lodash/debounce";
import baseURL from "../common/base-urls.js";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { useNavigate, useLocation } from "react-router-dom";
const Sidebar = () => {
    const navigate = useNavigate();
    const [state, setState] = useState({

        pagedata: [],
    });

    const [fullName, setFullName] = useState('');
    const [usercode, setUserCode] = useState('');
    useEffect(() => {
        const getUser = localStorage.getItem('user');
        if (getUser) {
            const { first_name, title, last_name, user_code } = JSON.parse(getUser);
            setFullName(`${title} ${first_name} ${last_name}`);
            setUserCode(`${user_code}`);
        }
    }, []); // Empty dependency array to run only once when the component mounts

    const [expandedMenu, setExpandedMenu] = useState(null);
    const handleMenuToggle = (index) => {
        setExpandedMenu(expandedMenu === index ? null : index);
    };
    const navigateToFormUrl = (url) => {
        navigate("/#/" + url);

    };

    // const navigateToFormUrl = (formUrl) => {
    //     try {
    //         navigate(`/${formUrl}`, { /* Any additional state or options */ });
    //     } catch (err) {
    //         console.error('Error navigating:', err);
    //     }
    // };
    let tblpages = {};
    const pagesURL = `report/pagelistrolewise`;
    const {
        data: tblhaspages,
        error: error1,
        isValidating: isValidating2,
    } = useSWR(pagesURL, () => getAPI(pagesURL, false), {
        revalidateOnReconnect: false,
        revalidateOnFocus: false,
    });
    //#region ====================================================== Update State ======================================================
    const updateState = (key, value) => {
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };
    //#endregion
    useEffect(() => {
        //console.log("result");
        // //console.log(result);
        // if(result.length==0)
        // {
        //     navigate("/");
        // }

        if (

            tblhaspages &&
            tblhaspages.data &&
            Array.isArray(tblhaspages.data.pagelist) &&
            tblhaspages.data.pagelist.length > 0
        ) {
            const inputData = tblhaspages.data.pagelist;
            // Initialize an empty result array
            const result = [];
            // Create a mapping object to store unique parent_form_id entries
            const parentFormMap = {};
            // Iterate over each item in the input data
            inputData.forEach(item => {
                const { parent_form_id, parent_form_name, icon_name, psort_no, form_id, form_name, form_url, sort_no } = item;
                // If the parent_form_id is not in the mapping object, add it
                if (!parentFormMap[parent_form_id]) {
                    parentFormMap[parent_form_id] = {
                        parent_form_id,
                        parent_form_name,
                        icon_name,
                        psort_no,
                        forms: [],
                    };
                    result.push(parentFormMap[parent_form_id]);
                }
                // Add the form details to the forms array
                parentFormMap[parent_form_id].forms.push({
                    form_id,
                    form_name,
                    sort_no,
                    form_url
                });
            });
            tblpages = result;
            updateState("pagedata", result);
        }
        // else {
        //     navigate("/");
        // }
    }, [tblhaspages]);



    //#region ====================================================== Get API ======================================================
    const getAPI = async (url, Auth) => {
        try {
            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                if (Auth) {
                    url += `&user_id=${getUser.user_id}`;
                }
                const response = await axios.get(`${baseURL.serverUrl}${url}`, {
                    headers: {
                        "x-access-token": getUser.token,
                        Authorization: `Bearer ${getUser.token}`,
                    },
                });
                return response.data;
            } else {
                return {};
            }
        } catch (err) {
            return {};
        }
    };
    //#endregion


    //#region ====================================================== Create State =====================================================
    return (
        <>
            <>
                <div className="nk-sidebar nk-sidebar-fixed is-theme" id="sidebar">
                    <div className="nk-sidebar-element nk-sidebar-head">
                        <div className="nk-sidebar-brand">
                            <a href="#" className="logo-link">
                                <div className="logo-wrap">
                                    <img className="logo-svg" src={logoimage} />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="nk-sidebar-element nk-sidebar-body">
                        <div className="nk-sidebar-content">
                            <div className="nk-sidebar-menu" data-simplebar>
                                <ul className="nk-menu">
                                    <li className="nk-menu-heading">
                                        <h6 className="overline-title">HOME</h6>
                                    </li>

                                    {state.pagedata && state.pagedata.length > 0 && (
                                        state.pagedata.map((x, index) => {
                                            return state.pagedata[index].forms.length > 1 ? (
                                                // Render your content for state.pagedata[index]
                                                <li className="nk-menu-item" key={x.parent_form_id}>
                                                    <a className="nk-menu-link nk-menu-toggle1" onClick={() => handleMenuToggle(index)}>
                                                        <span className="nk-menu-icon"><em className={`icon ni ${x.icon_name}`}></em></span>
                                                        <span className="nk-menu-text">{x.parent_form_name}</span>
                                                    </a>
                                                    {expandedMenu === index && (
                                                        <ul className="nk-menu-sub">
                                                            {x.forms.map((form) => (
                                                                <li className="nk-menu-item" key={form.form_id}>
                                                                    <a href={"#/" + form.form_url} className="nk-menu-link" onClick={(e) => {
                                                                        handleMenuToggle(index); // Toggle menu
                                                                        window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top smoothly
                                                                    }}>
                                                                        <span className="nk-menu-text">{form.form_name}</span>
                                                                    </a>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </li>
                                            ) : (
                                                // Render your default content
                                                <li className="nk-menu-item" key={x.parent_form_id}>
                                                    <a href={"#/" + x.forms[0].form_url} onClick={(e) => {
                                                        window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top smoothly
                                                    }} className="nk-menu-link">
                                                        <span className="nk-menu-icon"><em className={`icon ni ${x.icon_name}`}></em></span>
                                                        <span className="nk-menu-text">{x.forms[0].form_name}</span>
                                                    </a>
                                                </li>
                                            )
                                        })
                                    )}




                                </ul>
                            </div>

                            <div className="backlight">
                                <a href="#" className="nk-menu-link nk-menu-link1 bottomname">
                                    <div className="media media-md media-circle marginright12"><img src={userimage} alt="" className="img-thumbnail" /></div>
                                    <span className="bottomtitle">
                                        <span className="nk-menu-text colorwhite">{fullName}</span>
                                        <span className="nk-menu-text colorwhite font-size12">CODE : {usercode}</span></span>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        </>
    )
};
export default Sidebar;