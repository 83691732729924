import React, { useState, useEffect, useCallback } from "react";
import adminLayout from "../hoc/adminLayout.js";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import debounce from "lodash/debounce";
import baseURL from "../common/base-urls.js";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { useNavigate, useLocation } from "react-router-dom";
const AnnouncementEntry = () => {
    const navigate = useNavigate();
    const pageData = useLocation();
    // useEffect(() => {
    //     const currentTime = new Date();
    //     const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    //     //console.log(`=======================================`);
    //     //console.log(`========= Updated on ${formattedTime} =========`);
    //     //console.log(`=======================================`);
    //     //console.log(pageData);
    //     return () => {
    //     };
    // }, [pageData]);
    //#region ====================================================== Create State =====================================================
    const [state, setState] = useState({
        show_toast: false,
        toast_msg: "No record found",
        show_modal: false,
        saved: false,
        is_edit: false,
        data_assigned: false,
        modal_title: "Confirmation",
        modal_msg:
            "Are you absolutely certain you want to delete this record? This action cannot be reversed.",
        modal_button: "Delete",
        fields: {
            title: "",
            description: "",
            designations: ""
        },
        apifields: {
            title: "",
            description: "",
            designations: ""
        },
        oldfields: {
            title: "",
            description: "",
            designations: ""
        },
    });
    //#endregion
    //#region ====================================================== Shortcuts =====================================================
    document.addEventListener('keydown', function (event) {
        // Check if the key combination is Ctrl + S
        if (event.ctrlKey && event.key === 's') {
            // Prevent the default browser save behavior
            event.preventDefault();
            // Trigger the click event on your save button
            var saveButton = document.getElementById('btnsave');
            if (saveButton) {
                saveButton.click();
            }
        }
    });
    //#endregion
    //#region ====================================================== Update State ======================================================
    const updateState = (key, value) => {
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };
    //#endregion
    //#region ====================================================== Page Rights ======================================================
    const rightsURL = `user/chkuserrights?form_id=${23}`;
    const {
        data: tblhasrights,
        error: error1,
        isValidating: isValidating2,
    } = useSWR(rightsURL, () => getAPI(rightsURL, true), {
        revalidateOnReconnect: false,
        revalidateOnFocus: false,
    });
    //#endregion
    //#region ====================================================== Create or Edit Record ======================================================
    let tbldata = {};
    useEffect(() => {
        if (
            tblhasrights &&
            tblhasrights.data &&
            Array.isArray(tblhasrights.data.hasrights) &&
            tblhasrights.data.hasrights.length > 0 &&
            tblhasrights.data.hasrights[0].hasOwnProperty('update_status') &&
            tblhasrights.data.hasrights[0]['update_status']
        ) {
            if (pageData.state && state.is_edit == false && state.data_assigned == false) {
                const getData = async () => {
                    try {
                        const result = await getAPI(`announcement/${pageData.state.edit_id}`, false);
                        if (result && result.statusCode === 200 && result.success === true) {
                            tbldata = result;
                            //console.log(tbldata)
                            setState((prevState) => ({
                                ...prevState,
                                fields: {
                                    ...prevState.fields,
                                    title: tbldata.data.title,
                                    designations: tbldata.data.designations,
                                    description: tbldata.data.description
                                },
                            }));
                            updateState("data_assigned", true);
                        } else {
                            showToast("Something went wrong. Please try again later.");
                        }
                    } catch (err) {
                    }
                };
                getData();
                updateState("is_edit", true);
            }
        }
    }, [tblhasrights, pageData]);
    //#endregion 
    //#region ====================================================== Designation Dropdown Bind ======================================================
    let tbldesignations = {};
    let apiDesignation = `designation?page=1&limit=50`;
    const { data: designationdata, errordesignation, isValidatingdesignation } = useSWR(
        apiDesignation,
        () => getAPI(apiDesignation, false),
        {
            revalidateOnReconnect: false,
            revalidateOnFocus: false,
        }
    );
    tbldesignations = designationdata;
    //#endregion
    //#region ====================================================== Input Textbox ======================================================
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [name]: value,
            },
        }));
    };
    //#endregion
    //#region ====================================================== Checkbox Handler ======================================================
    const handleCheckboxActiveClick = () => {
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                is_all: !prevState.fields.is_all,
            },
        }));
    };
    //#endregion
    //#region ====================================================== Multi Select2 Dropdown ======================================================
    const handleSelectMultipleChange = (event) => {
        const selectedOptions = Array.from(
            event.target.selectedOptions,
            (option) => option.value
        );
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [event.target.name]: selectedOptions,
            },
        }));
    };
    //#endregion
    //#region ====================================================== Date validation ======================================================
    const handleDateChange = (event) => {
        const { name, value } = event.target;
        const { start_date, end_date } = state.fields;
        const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(value);
        if (!isValidDate) {
            showToast("Invalid date format");
            return;
        }
        if (name === "start_date" && new Date(value) > new Date(end_date)) {
            showToast("Start date cannot be greater than end date");
            return;
        }
        if (name === "end_date" && new Date(value) < new Date(start_date)) {
            showToast("End date cannot be less than start date");
            return;
        }
        // Update the state
        setState((prevState) => ({
            ...prevState,
            fields: { ...prevState.fields, [name]: value },
        }));
    };
    //#endregion
    //#region ====================================================== Clear or Create/Edit Record ======================================================
    const areObjectsEqual = (obj1, obj2) => {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            const val1 = obj1[key];
            const val2 = obj2[key];

            if (typeof val1 === 'object' && typeof val2 === 'object') {
                if (!areObjectsEqual(val1, val2)) {
                    return false;
                }
            } else if (val1 !== val2) {
                return false;
            }
        }

        return true;
    };
    useEffect(() => {
        if (state.saved === true) {
            if (state.is_edit === false) {
                const saveData = async () => {
                    try {
                        //console.log("state.oldfields")
                        //console.log(state.oldfields)
                        //console.log("state.apifields")
                        //console.log(state.apifields)
                        if (!areObjectsEqual(state.oldfields, state.apifields)) {
                            //  const newapiFields = state.apifields;
                            const newapiFields = { ...state.apifields };
                            const result = await postAPI('announcement', newapiFields, true);
                            if (result && result.statusCode === 201 && result.success === true) {
                                showToast("Record has been added successfully.");
                                clearData();
                            } else {
                                showToast("Something went wrong. Please try again later.");
                            }
                        } else {
                            showToast("Data has not changed. Please add unique records.");
                        }
                    } catch (err) {
                    }
                };
                saveData();
                updateState("saved", false);
            }
            else {

                const editData = async () => {
                    try {
                        //console.log("state.apifields");
                        //console.log(state.apifields);
                        const newapiFields = { ...state.apifields };
                        const result = await putAPI('announcement', newapiFields, false);
                        if (result && result.statusCode === 200 && result.success === true) {
                            showToast("Record has been updated successfully.");
                            clearData();
                        } else {
                            showToast("Something went wrong. Please try again later.");
                        }
                    } catch (err) {
                    }
                };
                editData();
                updateState("saved", false);
                updateState("is_edit", false);
                navigate("/announcementlist");
            }
        }
    }, [state.saved]);
    const saveClicked = () => {
        if (state.fields.title && state.fields.description && state.fields.designations != "") {
            setState((prevState) => ({
                ...prevState,
                saved: false
            }));

            setState((prevState) => ({
                ...prevState,
                saved: true,
                apifields: {
                    ...prevState.apifields,
                    title: prevState.fields.title,
                    designations: prevState.fields.designations,
                    description: prevState.fields.description
                },
            }));
        }
        else {
            if (!state.fields.title) {
                showToast("Title cannot be empty.");
            }
            else if (!state.fields.description) {
                showToast("Description cannot be empty.");
            }
            else if (state.fields.designations == "") {
                showToast("Designation cannot be empty.");
            }
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const debouncedSaveClicked = debounce(saveClicked, 500);
    const clearData = async () => {
        try {
            const updatedapifieldsState = {
                title: "",
                description: "",
                designations: ""
            };
            const updatedfieldState = {
                title: "",
                description: "",
                designations: ""
            };
            //console.log(state.apifields)
            updateState("oldfields", state.apifields);
            updateState("apifields", updatedapifieldsState);
            updateState("fields", updatedfieldState);
        } catch (err) { }
    };
    //#endregion
    //#region ====================================================== Back or FAQ ======================================================
    const Back = () => {
        try {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            navigate("/announcementlist");
        } catch (err) { }
    };
    const debouncedBack = debounce(Back, 500);
    const FAQ = () => {
        try {

            window.scrollTo({ top: 0, behavior: 'smooth' });
            navigate("/FAQ");
        } catch (err) { }
    };
    const debouncedFAQ = debounce(FAQ, 500);
    //#endregion
    //#region ====================================================== Toast Message set ======================================================
    const showToast = (message) => {
        updateState("show_toast", true);
        updateState("toast_msg", message);
        setTimeout(() => {
            updateState("show_toast", false);
        }, 5000);
    };
    const hideToast = () => {
        updateState("show_toast", false);
    };
    //#endregion
    //#region ====================================================== Get API ======================================================
    const getAPI = async (url, Auth) => {
        try {
            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                if (Auth) {
                    url += `&user_id=${getUser.user_id}`;
                }
                //console.log("inside GEt")
                //console.log(url)
                const response = await axios.get(`${baseURL.serverUrl}${url}`, {
                    headers: {
                        "x-access-token": getUser.token,
                        Authorization: `Bearer ${getUser.token}`,
                    },
                });

                //console.log(response.data)
                return response.data;
            } else {
                return {};
            }
        } catch (err) {
            return {};
        }
    };
    //#endregion
    //#region ====================================================== Post API ======================================================
    const postAPI = async (url, data, Auth) => {
        try {
            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                if (Auth) {
                    // Pass user_id through data
                    data.user_id = getUser.user_id;
                }
                //console.log("inside Post data");
                //console.log(data);
                const response = await axios.post(`${baseURL.serverUrl}${url}`, data, {
                    headers: {
                        "x-access-token": getUser.token,
                        Authorization: `Bearer ${getUser.token}`,
                        'Content-Type': 'application/json',
                    },
                });
                return response.data;
            } else {
                return {};
            }
        } catch (err) {
            return {};
        }
    };
    //#endregion 
    //#region ====================================================== Put API ======================================================
    const putAPI = async (url, data, Auth) => {
        try {
            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                if (Auth) {
                    data.user_id = getUser.user_id;
                }
                //console.log("inside put data");
                //console.log(data);
                const response = await axios.put(`${baseURL.serverUrl}${url}/${pageData.state.edit_id}`, data, {
                    headers: {
                        "x-access-token": getUser.token,
                        Authorization: `Bearer ${getUser.token}`,
                        'Content-Type': 'application/json',
                    },
                });
                return response.data;
            } else {
                return {};
            }
        } catch (err) {
            return {};
        }
    };
    //#endregion 
    return (
        <>
            <>
                {/* ====================================================== Head Section ====================================================== */}
                <div className="nk-block-head">
                    <div className="nk-block-head-between flex-wrap gap g-2">
                        {/* ====================================================== Page Name ====================================================== */}
                        <div className="nk-block-head-content">
                            <h2 className="nk-block-title">Announcement</h2>
                            <nav>
                                <ol className="breadcrumb breadcrumb-arrow mb-0">
                                    <li className="breadcrumb-item">
                                        <a href="#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a href="#">Announcement</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Entry
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        {/* ====================================================== Add New record filter export ====================================================== */}
                        <div className="nk-block-head-content">
                            <ul className="d-flex">
                                {tblhasrights &&
                                    tblhasrights.data &&
                                    Array.isArray(tblhasrights.data.hasrights) &&
                                    tblhasrights.data.hasrights.length > 0 &&
                                    tblhasrights.data.hasrights[0].hasOwnProperty("view_status") &&
                                    tblhasrights.data.hasrights[0]["view_status"] && (
                                        <>
                                            {!state.show_filter && (
                                                <>
                                                    <li>
                                                        <a
                                                            className="btn btn-outline-light btn-md d-md-inline-flex mr10"
                                                            onClick={debouncedFAQ}
                                                        >
                                                            <em className="icon ni ni-info"></em>
                                                            <span>FAQ</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="btn btn-primary d-none d-md-inline-flex"
                                                            onClick={debouncedBack}
                                                        >
                                                            <em className="icon ni ni-arrow-left"></em>
                                                            <span>Back</span>
                                                        </a>
                                                    </li>
                                                </>
                                            )}

                                        </>
                                    )}
                            </ul>
                        </div>
                    </div>
                </div>
                {/* ====================================================== Body section ====================================================== */}
                <div className="nk-block">
                    <ul className="nav nav-tabs mb-3 nav-tabs-s1 pos-sticky">
                        <li className="nav-item">    <a className="nav-link active" href="#summary">Summary</a>  </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="summary">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="summary">Summary</h5>
                                            <p className="ptitle">Select recipient's designation(s) from the dropdown and provide the announcement title and content.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label htmlFor="tax-class" className="form-label">Select designation</label>
                                                <div className="form-control-wrap">
                                                    <Select2
                                                        multiple
                                                        data={[
                                                            ...(tbldesignations && tbldesignations.data && tbldesignations.data.hasOwnProperty("designations") && tbldesignations.data.designations || []).map((x) => ({
                                                                text: x.designation_name,
                                                                id: x.designation_id,
                                                                key: x.designation_id.toString(),
                                                            })),
                                                        ]}
                                                        options={{
                                                            placeholder: "select an option",
                                                        }}
                                                        name="designations"
                                                        className="form-control"
                                                        value={state.fields.designations}
                                                        onChange={handleSelectMultipleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Title</label>
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter title"
                                                        name="title"
                                                        className="form-control"
                                                        value={state.fields.title}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Description</label>
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter description"
                                                        name="description"
                                                        className="form-control"
                                                        value={state.fields.description}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ====================================================== Footer Section ====================================================== */}
                <div className="divbottom">
                    <div className="bottombutton ">
                        <ul className="d-flex align-items-center gap g-3">
                            <li className="margintop0">
                                <a
                                    className="btn  btn-outline-light"
                                    onClick={debouncedBack}
                                >
                                    <em className="icon ni ni-arrow-left"></em>
                                    <span>Cancel</span>
                                </a>
                            </li>
                            {tblhasrights &&
                                tblhasrights.data &&
                                Array.isArray(tblhasrights.data.hasrights) &&
                                tblhasrights.data.hasrights.length > 0 &&
                                tblhasrights.data.hasrights[0].hasOwnProperty("write_status") &&
                                tblhasrights.data.hasrights[0]["write_status"] && (
                                    <>
                                        <li className="margintop0"> <button
                                            type="button"
                                            className="btn btn-primary"
                                            name="btnsave"
                                            id="btnsave"
                                            onClick={debouncedSaveClicked}
                                        >
                                            <span>{state.is_edit ? 'Update record' : 'Create record'}</span>
                                        </button> </li>
                                    </>
                                )}
                        </ul>
                    </div>
                </div>
                {/* ====================================================== Toast message ====================================================== */}
                {state.show_toast && (
                    <div
                        className="toast align-items-center text-bg-primary border-0 fade show toastmsg"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                    >
                        <div className="d-flex">
                            <div className="toast-body">{state.toast_msg}</div>
                            <button
                                type="button"
                                className="btn-close btn-close-white me-2 m-auto"
                                data-bs-dismiss="toast"
                                onClick={(e) => hideToast()}
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                )}
            </>
        </>
    );
};
export default adminLayout(AnnouncementEntry);